interface ParsedElement {
    type: "text" | "image";
    content: string;
}

export const parseRichText = (htmlString: string): ParsedElement[] => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const result: ParsedElement[] = [];

    const processNode = (node: Node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            // if (textContent) {
            //     result.push({ type: "text", content: textContent });
            // } else {
            //     result.push({ type: "text", content: "" });解析富文本
            // }
        } else if (node.nodeType === Node.ELEMENT_NODE) {
            const element = node as HTMLElement;
            if (element.tagName.toLowerCase() === "p") {
                const textContent = element.textContent?.trim();
                if (textContent) {
                    result.push({ type: "text", content: textContent });
                } else {
                    result.push({ type: "text", content: "" });
                }
            }
            if (element.tagName.toLowerCase() === "img") {
                const src = element.getAttribute("src");
                if (src) {
                    result.push({ type: "image", content: src });
                }
            } else {
                for (let i = 0; i < node.childNodes.length; i++) {
                    processNode(node.childNodes[i]);
                }
            }
        }
    };

    processNode(doc.body);

    return result;
};
