export default {
    title: "修改头像",
    custom_font: "自定义字体",
    change_color: "选择颜色",
    input_placeholder: "请输入头像文字",
    tooltip_Info: "如果仅选择颜色，将使用该颜色的默认头像.",

    cut_picture: "裁剪头像",
    cut_sure: "提交",
};
