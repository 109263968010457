import React, { useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Button, Modal } from "antd";
import styles from "./index.module.less";
import { useTranslation } from "react-i18next";
import SparkMD5 from "spark-md5";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setFileSum, setFileData, setFileUrl } from "../../../store/file";

interface Props {
    imgURL: string;
    onCancel: () => void;
    closeModel: () => void;
}

const ReactCropper: React.FC<Props> = ({ imgURL, onCancel, closeModel }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // 文件名
    const fileName = useAppSelector((state) => state.file.file_name);
    const [cropperInfo, setCropperInfo] = useState<Cropper>();

    function dataURItoBlob(dataURI: string) {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    const onBut = () => {
        if (typeof cropperInfo !== "undefined") {
            // setBtn(true);
            const blobInfo = dataURItoBlob(cropperInfo.getCroppedCanvas().toDataURL());
            // console.log(blobInfo);
            const file = new File([blobInfo], fileName, { type: blobInfo.type, lastModified: Date.now() });
            // 存文件
            dispatch(setFileData(file));
            const fn = async (file: File) => {
                const getFileData = (file: Blob) => {
                    const reader = new FileReader();
                    reader.addEventListener("load", () => {
                        if (reader.result !== null) {
                            const url = URL.createObjectURL(blobInfo);
                            // 存地址
                            dispatch(setFileUrl(url));
                            const file_md5 = SparkMD5.ArrayBuffer.hash(reader.result as ArrayBuffer);
                            // 存文件md5值
                            dispatch(setFileSum(file_md5));
                            onCancel();
                            closeModel();
                        }
                    });

                    reader.readAsArrayBuffer(file);
                };
                getFileData(file);
            };
            void fn(file);
        }
    };

    return (
        <Modal
            width={400}
            title={t("avatar.cut_picture")}
            className="cropperModal"
            open={true}
            centered
            onCancel={onCancel}
            footer={false}
        >
            <div className={styles.cropperModalMain} onClick={(e) => e.stopPropagation()}>
                <Cropper
                    style={{ maxHeight: "70vh" }}
                    zoomTo={0}
                    initialAspectRatio={1 / 1}
                    preview=".img-preview"
                    src={imgURL}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    onInitialized={(instance) => {
                        setCropperInfo(instance);
                    }}
                    guides={true}
                    movable={true}
                    aspectRatio={1 / 1}
                    dragMode={"move"}
                    onClick={(e) => e.stopPropagation()}
                />
            </div>
            <div className={styles.cropperModalFooter}>
                <Button onClick={onBut}>{t("avatar.cut_sure")}</Button>
            </div>
        </Modal>
    );
};
export default ReactCropper;
