import axios from "axios";
import type { AxiosResponse, AxiosRequestConfig } from "axios";
import { getToken, removeToken } from "./auth";

const instance = axios.create({
    timeout: 10000,
    // baseURL: "/api",
    baseURL: "https://chat-api.thetinyworld.cn",
});

// 不需要token的白名单
const whiteList = ["/user/oauth/startnewsession", "/user/oauth/auth"];

// 请求拦截
instance.interceptors.request.use(
    (config) => {
        // 获取存储的token
        const token = getToken();
        // 判断token是否存在以及是否是登录
        if (token === undefined && !whiteList.includes(config.url as string)) {
            removeToken();
            throw new Error("user not login");
        } else if (token !== undefined) {
            // token存在时把他写入请求头
            config.headers["x-zhifou-session"] = token;
            return config;
        } else {
            return config;
        }
    },
    async (error) => {
        console.log(error);

        return await Promise.reject(error);
    }
);

// 响应拦截
instance.interceptors.response.use(
    (response) => {
        if (!response.data) {
            throw new Error("无响应");
        } else {
            return response;
        }
    },
    (err) => {
        let message = "";
        if (err.response == null) {
            message = "请求没有响应";
        } else {
            const {
                response: { status },
            } = err;
            switch (status) {
                case 400:
                    return Promise.reject((message = "客户端请求的语法错误，服务器无法理解"));
                case 401:
                    return Promise.reject((message = "请求要求用户的身份认证"));
                case 403:
                    return Promise.reject((message = "服务器理解请求客户端的请求，但是拒绝执行此请求"));
                case 404:
                    return Promise.reject((message = "服务器无法根据客户端的请求找到资源"));
                case 405:
                    return Promise.reject((message = "客户端请求中的方法被禁止"));
                case 500:
                    return Promise.reject((message = "服务器内部错误，无法完成请求"));
                case 502:
                    return Promise.reject((message = "充当网关或代理的服务器，从远端服务器接收到了一个无效的请求"));
                case 504:
                    return Promise.reject((message = "充当网关或代理的服务器，未及时从远端服务器获取请求"));
                case 505:
                    return Promise.reject((message = "服务器不支持请求的HTTP协议的版本, 无法完成处理"));
                default:
                    message = "未知错误";
            }
        }
        return Promise.reject(message);
    }
);

export default async function http<T = Record<string, unknown>>(
    config: AxiosRequestConfig
): Promise<IApi.IResponse<T>> {
    const res: AxiosResponse<IApi.IResponse<T>> = await instance.request(config);
    return res.data;
}
