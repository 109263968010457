export default {
    home: "Home",
    user_center: "Profile",
    app_list: "All sessions",
    version: "Version",
    settings: "Settings",
    zhifou: "Zhifou",
    ok: "Ok",
    cancel: "Cancel",
    language: "Language",
    dev_token: "develop token",
    yesterday: "Yesterday",
    headPortrait: "Head Portrait",
};
