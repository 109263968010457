import React, { useEffect, useState } from "react";
import style from "./index.module.less";
import { Avatar, Checkbox, Form, Input, Modal, Switch, message } from "antd";
import { GetSessionList, RobotsInfo } from "../robotsList";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { DeleteOutlined, SearchOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { useTranslation } from "react-i18next";
import { createChatGroup } from "../../services/api/message";
import { getDevToken, getToken } from "../../services/auth";
import ChangeAvatar from "../changeAvatar";
import { newGroupAvatarUploadSession, commituploadsessionApi } from "../../services/api/uploading";
import axios from "axios";
import { getSessionList } from "../../store/robots";
import { setFileName, setFileSum, setFileUrl, setFileData } from "../../store/file";

interface PropsType {
    closeModel: () => void;
}

interface DataType extends RobotsInfo {
    // 该属性用以左边切换效果展示
    checked: boolean;
}

const CreateChatGroup = (props: PropsType) => {
    const { closeModel } = props;
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false);
    const [openSetAvatar, setOpenSetAvatar] = useState<boolean>(false);
    const [avatarText, setAvatarText] = useState<string>("");
    const [avatarColor, setAvatarColor] = useState<string>("#3370FF");
    // 左边的数据
    const [leftData, setLeftData] = useState<DataType[]>([]);
    // 右边的数据
    const [rightData, setRightData] = useState<RobotsInfo[]>([]);
    // 会话列表
    const sessionList = useAppSelector((state) => state.robots.sessionList);
    // 文件名
    const fileName = useAppSelector((state) => state.file.file_name);
    // 文件url
    const fileUrl = useAppSelector((state) => state.file.file_url);
    // 文件url
    const fileSum = useAppSelector((state) => state.file.file_sum);
    // 文件url
    const fileData = useAppSelector((state) => state.file.fileData);
    const token = getToken();
    const dev_token = getDevToken();

    useEffect(() => {
        // 重置数据
        dispatch(setFileName(""));
        dispatch(setFileUrl(""));
        dispatch(setFileSum(""));
        dispatch(setFileData(null));
        // 筛选成员
        if (sessionList.length > 0) {
            const list: DataType[] = [];
            sessionList.forEach((item) => {
                // 筛选类型为app的会话
                if (item.type === "app") {
                    // 初始化数据
                    list.push({ ...item, checked: false });
                }
            });
            setLeftData(list);
        }
    }, []);

    useEffect(() => {
        if (form) {
            form.setFieldsValue({
                isolate_app: false,
                desc: "",
                avatar: "",
            });
        }
    }, [form]);

    // 保存头像的颜色和文本
    const onOk = (color: string, text: string) => {
        setAvatarColor(color);
        setAvatarText(text);
    };

    // 点击左边成员
    const onChange = (e: CheckboxChangeEvent, data: DataType) => {
        data.checked = true;
        if (!rightData.includes(data)) {
            setRightData([...rightData, data]);
        } else {
            const newData = rightData.filter((item) => item.session !== data.session);
            setRightData(newData);
            data.checked = false;
        }
    };

    // 删除右边成员
    const deleteItem = (data: RobotsInfo) => {
        // 更新左边数据
        const list: DataType[] = [];
        leftData.forEach((item) => {
            if (item.session === data.session) {
                list.push({ ...item, checked: false });
            } else {
                list.push(item);
            }
        });
        setLeftData(list);
        // 更新右边数据
        const newData = rightData.filter((item) => item.session !== data.session);
        setRightData(newData);
    };

    // 上传头像
    const uploadAvatar = async () => {
        if (fileUrl) {
            const info = await newGroupAvatarUploadSession({ file_name: fileName });
            if (info.error.code === 0) {
                const { content_type, res_id, upload_url } = info.data as {
                    content_type: string;
                    res_id: string;
                    upload_url: string;
                };
                if (token !== undefined) {
                    const url = upload_url.slice(upload_url.indexOf(".com") + 4);
                    // 代理地址
                    const request = axios.create({
                        timeout: 10000,
                        baseURL: "/upload_url",
                    });
                    // 上传
                    const info = await request.request({
                        method: "put",
                        data: fileData,
                        url: url,
                        headers: {
                            "x-zhifou-session": token,
                            "Content-Type": content_type,
                        },
                    });
                    if (info.status === 200) {
                        // 提交确认
                        const info = await commituploadsessionApi({ file_sum: fileSum, res_id: res_id });
                        if (info.error.code === 0) {
                            // 上传成功后将资源id返回
                            return res_id;
                        }
                    }
                }
            }
        } else {
            return "";
        }
    };

    // 创建群组
    const onSubmit = async () => {
        await form.validateFields();
        if (rightData.length > 0) {
            // setConfirmLoading(true);
            // 获取表单的信息
            const obj = form.getFieldsValue();
            let avatar = "";
            const uploadFlag = await uploadAvatar();
            if (uploadFlag) {
                avatar = `res://${uploadFlag}`;
            } else {
                avatarText && avatarColor
                    ? (avatar = `text://?t=${avatarText}&color=${avatarColor}`)
                    : !avatarText && avatarColor
                    ? (avatar = `color://${avatarColor}`)
                    : avatarText && !avatarColor
                    ? (avatar = `text://?t=${avatarText}`)
                    : "";
            }
            // 群成员会话id的集合
            const membersID: string[] = rightData.map((item) => item.session);
            const param: IApi.CreateChatGroup = {
                name: obj.name,
                desc: obj.desc,
                avatar: avatar ? avatar : "",
                isolate_app: obj.isolate_app,
                members: membersID,
            };
            const info = await createChatGroup(param);

            if (info.error.code === 0) {
                setConfirmLoading(false);
                message.success(t("createChatGroup.created_successfully"));
                // 获取会话列表
                if (token) {
                    const param: GetSessionList = {
                        token: token,
                        dev_token: dev_token ? dev_token : "",
                    };
                    dispatch(getSessionList(param));
                }
                closeModel();
            } else {
                setConfirmLoading(false);
                message.error(t("createChatGroup.creation_failed"));
                closeModel();
            }
        } else {
            message.error(t("createChatGroup.no_members"));
        }
    };

    return (
        <Modal
            title={t("createChatGroup.title")}
            centered
            open={true}
            onOk={onSubmit}
            onCancel={closeModel}
            width={800}
            okText={t("createChatGroup.okText")}
            cancelText={t("createChatGroup.cancelText")}
            confirmLoading={confirmLoading}
        >
            <Form
                labelCol={{ span: `${localStorage.getItem("i18nextLng") === "zh" ? "4" : "5"}` }}
                wrapperCol={{ span: 18 }}
                form={form}
            >
                <Form.Item
                    label={t("createChatGroup.form_group_name")}
                    name="name"
                    rules={[{ required: true, message: `${t("createChatGroup.verify_names")}` }]}
                >
                    <Input
                        autoComplete="off"
                        showCount
                        maxLength={10}
                        placeholder={`${t("createChatGroup.placeholder_name")}`}
                    />
                </Form.Item>
                <Form.Item label={t("createChatGroup.form_group_desc")} name="desc" style={{ marginBottom: "0px" }}>
                    <TextArea
                        autoSize={{ minRows: 2, maxRows: 4 }}
                        showCount
                        maxLength={50}
                        placeholder={`${t("createChatGroup.placeholder_desc")}`}
                    />
                </Form.Item>
                <Form.Item label={t("createChatGroup.form_group_avatar")} name="avatar">
                    <div className={style.avatarBox} onClick={() => setOpenSetAvatar(true)}>
                        <Avatar
                            size="large"
                            src={fileUrl ? fileUrl : ""}
                            style={{
                                backgroundColor: `${avatarColor}`,
                                verticalAlign: "middle",
                                marginRight: "10px",
                            }}
                        >
                            {avatarText ? avatarText : ""}
                        </Avatar>
                        <div>{t("createChatGroup.click_change")}</div>
                    </div>
                </Form.Item>
                <Form.Item label={t("createChatGroup.form_isolate_app")} name="isolate_app" valuePropName="checked">
                    <Switch />
                </Form.Item>
                <Form.Item label={t("createChatGroup.form_group_members")} style={{ marginBottom: "0px" }}>
                    <div className={style.contentBox}>
                        <div className={style.contentLeftBox}>
                            {/* <Input
                            prefix={<SearchOutlined style={{ marginRight: "5px" }} />}
                            style={{ marginBottom: "10px" }}
                        /> */}
                            {leftData.length > 0 ? (
                                leftData.map((item, index) => {
                                    return (
                                        <Checkbox
                                            key={index}
                                            onChange={(e) => onChange(e, item)}
                                            checked={item.checked}
                                            style={{ marginBottom: "10px" }}
                                        >
                                            {item.avatar ? (
                                                <Avatar
                                                    size="small"
                                                    src={item.avatar}
                                                    style={{
                                                        backgroundColor: "#005EFF",
                                                        verticalAlign: "middle",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {item.name}
                                                </Avatar>
                                            ) : (
                                                <Avatar
                                                    size="small"
                                                    style={{
                                                        backgroundColor: "#005EFF",
                                                        verticalAlign: "middle",
                                                        marginRight: "10px",
                                                    }}
                                                >
                                                    {item.name}
                                                </Avatar>
                                            )}
                                            {item.name}
                                        </Checkbox>
                                    );
                                })
                            ) : (
                                <span>{t("createChatGroup.no_data")}</span>
                            )}
                        </div>
                        <div className={style.contentRightBox}>
                            <div>
                                {localStorage.getItem("i18nextLng") === "zh"
                                    ? `${t("createChatGroup.selected")} ${rightData.length} ${t(
                                          "createChatGroup.people"
                                      )}`
                                    : `${rightData.length} ${t("createChatGroup.selected_people")}`}{" "}
                            </div>
                            {rightData.length > 0
                                ? rightData.map((item, index) => {
                                      return (
                                          <div key={index} className={style.dataItem}>
                                              <div>
                                                  {item.avatar ? (
                                                      <Avatar
                                                          size="small"
                                                          src={item.avatar}
                                                          style={{
                                                              backgroundColor: "#005EFF",
                                                              verticalAlign: "middle",
                                                              marginRight: "10px",
                                                          }}
                                                      >
                                                          {item.name}
                                                      </Avatar>
                                                  ) : (
                                                      <Avatar
                                                          size="small"
                                                          style={{
                                                              backgroundColor: "#005EFF",
                                                              verticalAlign: "middle",
                                                              marginRight: "10px",
                                                          }}
                                                      >
                                                          {item.name}
                                                      </Avatar>
                                                  )}
                                                  {item.name}
                                              </div>
                                              <DeleteOutlined
                                                  style={{ cursor: "pointer" }}
                                                  onClick={() => deleteItem(item)}
                                              />
                                          </div>
                                      );
                                  })
                                : null}
                        </div>
                    </div>
                </Form.Item>
            </Form>
            {openSetAvatar ? <ChangeAvatar closeModel={() => setOpenSetAvatar(false)} onOK={onOk} /> : null}
        </Modal>
    );
};

export default CreateChatGroup;
