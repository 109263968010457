export default {
    title: "Change avatar",
    custom_font: "Custom font",
    change_color: "Chang color",
    input_placeholder: "Please enter the avatar text",
    tooltip_Info: "If only a color is selected, the default avatar of that color will be used.",

    cut_picture: "Cropped avatar",
    cut_sure: "Submit",
};
