export default {
    login: "登录",
    logout: "退出登录",
    login_successful: "登录成功",

    // empower
    title: "请根据登录成功后的地址栏内容进行授权",
    code_checking: "授权码为空",
    state_checking: "授权状态为空",
    submit: "提交",
    cancel: "取消",
    welcome: "欢迎来到知否",
    login_fail: "登录授权失败",
};
