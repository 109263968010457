import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import store from "./store";
import Router from "./router/router";

const targetProtocol = "https:";
if (process.env.NODE_ENV !== "development") {
    if (window.location.protocol != targetProtocol) {
        window.location.href = targetProtocol + window.location.href.substring(window.location.protocol.length);
    }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    // <React.StrictMode>
    <Provider store={store}>
        <Router />
    </Provider>
);
