export default {
    title: "创建群组",
    form_group_name: "群名称",
    form_group_desc: "群描述",
    form_group_avatar: "群头像",
    form_isolate_app: "应用限制",
    form_group_members: "群成员",

    placeholder_name: "请输入群名称",
    placeholder_desc: "请输入群描述",
    verify_names: "群名称不能为空",
    click_change: "点击修改",

    no_data: "没有成员可选择",
    okText: "创建",
    cancelText: "取消",

    no_members: "没有群成员",
    creation_failed: "创建失败",
    created_successfully: "创建成功",
    selected: "已选",
    people: "人",
};
