export default {
    title: "Create group",
    form_group_name: "Group name",
    form_group_desc: "Group describe",
    form_group_avatar: "Group avatar",
    form_isolate_app: "Application isolation",
    form_group_members: "Group members",

    placeholder_name: "Please input group name",
    placeholder_desc: "Please input group desc",
    verify_names: "Name cannot be empty",
    click_change: "Click to modify",

    no_data: "No members to choose from",
    okText: "Create",
    cancelText: "Cancel",

    no_members: "No members",
    creation_failed: "Creation failed",
    created_successfully: "Created successfully",
    selected_people: "people selected",
};
