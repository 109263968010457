import http from "../request";

// 登录会话
export const startNewSessionApi = async <T>(): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/user/oauth/startnewsession",
        method: "post",
        data: {},
    });
};

// 用户系统授权码进行校检
export const oauthAuth = async <T>(data: IApi.OauthAuth): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/user/oauth/auth",
        method: "post",
        data: { ...data },
    });
};

// 用户信息
export const getUserInfo = async <T>(): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/user/info",
        method: "post",
        data: {},
    });
};

// 退出登录
export const userLogout = async <T>(): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/user/logout",
        method: "post",
        data: {},
    });
};

// 会话列表
export const sessionList = async <T>(data: IApi.SessionList): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/session/list",
        method: "post",
        data: { ...data },
    });
};
