import SparkMD5 from "spark-md5";
import { commituploadsessionApi, newuploadsessionApi } from "../../../services/api/uploading";
import axios from "axios";
import { getToken } from "../../../services/auth";
import { sendMessageAPI } from "../../../services/api/message";
import { useAppDispatch } from "../../../store/hooks";
import { setRobotsTime } from "../../../store/robots";

export const useSendMessage = () => {
    const dispatch = useAppDispatch();

    // base64装Blob
    function dataURItoBlob(dataURI: string) {
        const byteString = atob(dataURI.split(",")[1]);
        const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    }

    // 上传文件到服务器
    const uploadFileData = (file: Blob, res_id: string, session: string, type: "file" | "img") => {
        const reader = new FileReader();
        reader.addEventListener("load", async () => {
            if (reader.result !== null) {
                const filemd5 = SparkMD5.ArrayBuffer.hash(reader.result as ArrayBuffer);

                await commituploadsessionApi({
                    res_id: res_id,
                    file_sum: filemd5,
                });
                await sendMessage(session, `res://${res_id}`, type);
            }
        });

        reader.readAsArrayBuffer(file);
    };

    // url截取从start开始到结尾的所有字符
    function GetUrlRelativePath(url: string) {
        const arrUrl = url.split("//");

        const start = arrUrl[1].indexOf("/");
        const relUrl = arrUrl[1].substring(start); //stop省略，截取从start开始到结尾的所有字符

        // 去除参数
        // if (relUrl.indexOf("?") != -1) {
        //     relUrl = relUrl.split("?")[0];
        // }
        return relUrl;
    }

    // 上传文件
    const uploadFile = async (base64: string, session: string, type: "file" | "img", name = "IMAGE") => {
        if (typeof base64 !== "undefined") {
            // 转换为Blob文件
            const blobInfo = dataURItoBlob(base64);

            // 转换为File文件
            const file = new File([blobInfo], name);

            try {
                // 创建新上传会话获取数据
                const info = (await newuploadsessionApi({
                    session: session,
                    file_name: file.name,
                })) as unknown as {
                    data: {
                        content_type: string;
                        res_id: string;
                        upload_url: string;
                    };
                };

                // 返回参数中的url
                const url = info.data.upload_url;

                const token = getToken();
                if (!token) return;

                // 代理地址
                const request = axios.create({
                    timeout: 10000,
                    // baseURL: "/upload_url",
                });

                // 根据返回的url进行网络请求
                await request.request({
                    method: "put",
                    data: file,
                    url: url.replace(/^http/, "https"),
                    // url: GetUrlRelativePath(url),
                    headers: {
                        "x-zhifou-session": token,
                        "Content-Type": info.data.content_type,
                    },
                });
                uploadFileData(file, (info.data as { res_id: string }).res_id, session, type);
            } catch (error) {
                console.log(error);
            }
        }
    };

    // 发送调用接口
    const sendMessage = async (session: string, value: string, type: "text" | "file" | "img") => {
        let sendData: {
            session: string;
            type: "text" | "file" | "image";
            content: Record<string, string>;
        };

        if (type === "text") {
            // 文本
            sendData = {
                session,
                type: "text",
                content: {
                    text: value,
                },
            };
        } else if (type === "img") {
            // 图片
            sendData = {
                session,
                type: "image",
                content: {
                    image_uri: value,
                },
            };
        } else {
            // 文件
            sendData = {
                session,
                type: "file",
                content: {
                    file_uri: value,
                },
            };
        }

        // 发送到服务器
        const { data } = await sendMessageAPI(sendData);

        // 返回值存到redux
        // dispatch(
        //     setRobotsTime({
        //         [(data as { id: string }).id]: {
        //             flag: true,
        //             timestamp: (data as { timestamp: number }).timestamp,
        //         },
        //     })
        // );
    };

    return { uploadFile, sendMessage };
};
