import React from "react";
import { createHashRouter, RouterProvider } from "react-router-dom";
import App from "../App";
import Login from "../components/login/login";
import { useTranslation } from "react-i18next";

export default function router() {
    const { t, i18n } = useTranslation();
    React.useEffect(() => {
        document.title = t("home.zhifou");
    }, [i18n.language]);
    const router = createHashRouter([
        {
            path: "/app",
            element: <App />,
        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/",
            element: <Login />,
            errorElement: <div>糟糕了, 没有找到页面</div>,
        },
    ]);
    return <RouterProvider router={router} />;
}
