import React, { useEffect, useState } from "react";
import { Divider, Dropdown, MenuProps, Space } from "antd";
import RobotsList, { GetSessionList } from "./components/robotsList";
import { UserInfo } from "./components/user";
import style from "./home.module.less";
import ChatList from "./components/chatList";
import { getDevToken, getToken, removeToken } from "./services/auth";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getUserInfo } from "./services/api/login";
import { useTranslation } from "react-i18next";
import { setUserInfo } from "./store/userManage";
import Settings from "./components/settings";
import Header from "./components/header";
import CreateChatGroup from "./components/createChatGroup";
import ApplicationSearch from "./components/applicationSearch";
import ApplicationInfo from "./components/applicationInfo";
import { PlusOutlined, SearchOutlined, SettingOutlined, UsergroupAddOutlined } from "@ant-design/icons";
import useHeartbeat from "./hooks/useHeartbeat";
import useInputState from "./hooks/useInputState";
import { getSessionList, setHeartbeatNumber, setMobile } from "./store/robots";

const Home = () => {
    const { t, i18n } = useTranslation();
    const token = getToken();
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState<boolean>(false);
    const [applicationSearchModel, setApplicationSearchModel] = useState<boolean>(false);
    const [createGroupModel, setCreateGroupModel] = useState<boolean>(false);
    const [shareAppModel, setShareAppModel] = useState<boolean>(false);
    const [shareCode, setShareCode] = useState<string>("");

    // 会话信息
    const selectAppInfo = useAppSelector((state) => state.robots.selectRobotsInfo);
    // 心跳参数
    const robotTime = useAppSelector((state) => state.robots.robotsTime);
    // 会话列表
    const sessionList = useAppSelector((state) => state.robots.sessionList);
    // 是否是移动端
    const isMobile = useAppSelector((state) => state.robots.mobile);

    useEffect(() => {
        window.addEventListener(
            "resize",
            () => {
                if (document.body.clientWidth < 500) {
                    dispatch(setMobile(true));
                } else {
                    dispatch(setMobile(false));
                }
            },
            true
        );
    }, []);

    const dev_token = getDevToken();

    // 心跳hooks
    const { data, run, cancel } = useHeartbeat(robotTime);
    // 输入状态轮循的hooks
    const returnData = useInputState();

    useEffect(() => {
        if (sessionList.length > 0) {
            // 开启轮循
            run();
            // 开启输入状态轮循
            returnData.run();
        } else {
            // 取消轮循
            cancel();
            // 取消输入状态轮循
            returnData.cancel();
        }
    }, [sessionList]);

    useEffect(() => {
        if (data) {
            dispatch(setHeartbeatNumber(data));
        }
    }, [data]);

    useEffect(() => {
        if (token) {
            const param: GetSessionList = {
                token: token,
                dev_token: dev_token ? dev_token : "",
            };
            dispatch(getSessionList(param));
        }
    }, []);

    React.useEffect(() => {
        document.title = t("home.zhifou");
    }, [i18n.language]);

    useEffect(() => {
        if (token) {
            getUserAndRobotsInfo();
        }
    }, [token]);

    useEffect(() => {
        if (location.href) {
            const param = window.location.href.split("?")[1];
            // 提取url的参数
            const result = new URLSearchParams(param);
            // 有值说明是通过分享链接进入
            const code = result.get("code");
            if (code) {
                // 判断是否是登录状态
                if (token) {
                    setShareCode(code);
                    setShareAppModel(true);
                } else {
                    localStorage.setItem("code", code);
                }
            } else {
                setShareAppModel(false);
            }
        }
    }, [location.href]);

    useEffect(() => {
        if (localStorage.getItem("code")) {
            location.href = `${location.href}/?code=${localStorage.getItem("code")}`;
        }
    }, []);

    const getUserAndRobotsInfo = () => {
        getUserInfo()
            .then((info) => {
                const { code } = info.error;
                if (code === 0) {
                    const userInfo = info.data as UserInfo;
                    dispatch(setUserInfo(userInfo));
                } else {
                    removeToken();
                    location.reload();
                }
            })
            .catch((e) => {
                removeToken();
                location.reload();
            });
    };

    const items: MenuProps["items"] = [
        {
            label: (
                <div onClick={() => setCreateGroupModel(true)}>
                    <UsergroupAddOutlined style={{ marginRight: "5px" }} />
                    {t("createChatGroup.title")}
                </div>
            ),
            key: "0",
        },
    ];

    // 关闭应用信息弹框
    const closeModel = () => {
        setShareAppModel(false);
        const param = window.location.href.split("/?")[0];
        location.href = param;
        localStorage.setItem("code", "");
    };

    return (
        <div className={style.homeInfo}>
            <Header openSettingsModel={() => setOpen(true)} />
            <div className={style.content}>
                {isMobile ? (
                    <>
                        {selectAppInfo && isMobile ? (
                            <div className={style.nav}>
                                <ChatList />
                            </div>
                        ) : (
                            <div className={style.home}>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    {t("home.app_list")}
                                    <div className={style.controlBox}>
                                        <SearchOutlined
                                            title={`${t("applicationSearch.title")}`}
                                            onClick={() => setApplicationSearchModel(true)}
                                        />
                                        <Dropdown menu={{ items }} trigger={["click"]}>
                                            <PlusOutlined style={{ cursor: "pointer" }} />
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className={style.listBox}>
                                    <RobotsList />
                                </div>
                                <Divider />
                                <Space
                                    direction="vertical"
                                    size="middle"
                                    style={{ display: "flex", marginBottom: "20px" }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            // marginBottom: "20px",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                    >
                                        <span>
                                            {t("home.version")}：{__VERSION__}{" "}
                                        </span>
                                        <span>
                                            <SettingOutlined
                                                onClick={() => {
                                                    //
                                                    setOpen(true);
                                                }}
                                            />
                                        </span>
                                    </div>
                                </Space>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        <div className={style.home}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                {t("home.app_list")}
                                <div className={style.controlBox}>
                                    <SearchOutlined
                                        title={`${t("applicationSearch.title")}`}
                                        onClick={() => setApplicationSearchModel(true)}
                                    />
                                    <Dropdown menu={{ items }} trigger={["click"]}>
                                        <PlusOutlined style={{ cursor: "pointer" }} />
                                    </Dropdown>
                                </div>
                            </div>
                            <div className={style.listBox}>
                                <RobotsList />
                            </div>
                            <Divider />
                            <Space direction="vertical" size="middle" style={{ marginBottom: "14px" }}>
                                <span>
                                    {t("home.version")}：{__VERSION__}{" "}
                                </span>
                            </Space>
                        </div>

                        <div className={style.nav}>
                            <ChatList />
                        </div>
                    </>
                )}
            </div>

            <Settings isShow={open} onCancel={(isShow) => setOpen(isShow)} />

            {/* 创建群组 */}
            {createGroupModel ? <CreateChatGroup closeModel={() => setCreateGroupModel(false)} /> : null}
            {/* 搜索应用 */}
            {applicationSearchModel ? <ApplicationSearch closeModel={() => setApplicationSearchModel(false)} /> : null}
            {/* 通过分享码获取应用信息 */}
            {shareAppModel ? <ApplicationInfo closeModel={closeModel} code={shareCode} /> : null}
        </div>
    );
};

export default Home;
