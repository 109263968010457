import moment from "moment";
import i18n from "../i18n";

export function dateDiff(props: number) {
    if (moment(props).isSame(moment(), "day")) {
        return moment(props).format("HH:mm");
    } else if (moment(props).isSame(moment().subtract(1, "day"), "day")) {
        return i18n.t("home.yesterday") + moment(props).format(" HH:mm");
    } else {
        return moment(props).format("YYYY-MM-DD HH:mm");
    }
}
