import Cookies from "js-cookie";
// 定义token
const KNOW_NO_TOKEN = "know_no_token";

// 获取token
export function getToken(): string | undefined {
    return Cookies.get(KNOW_NO_TOKEN);
}

// 存储token
export function setToken(token: string): string | undefined {
    return Cookies.set(KNOW_NO_TOKEN, token);
}

// 删除token
export function removeToken(): void {
    return Cookies.remove(KNOW_NO_TOKEN);
}

// 定义Polling Time
const KNOW_NO_POLLING_TIME = "know_no_polling_time";

// 获取Polling Time
// export function getPollingTime(): string | null {
//     return localStorage.getItem(KNOW_NO_POLLING_TIME);
// }

// 存储 Polling Time
// export function setPollingTime(token: string) {
//     localStorage.setItem(KNOW_NO_POLLING_TIME, token);
// }

// 删除Polling Time
export function removePollingTime(): void {
    return localStorage.removeItem(KNOW_NO_POLLING_TIME);
}

// 定义Polling Time
const KNOW_NO_LANG = "know_no_lang";

// 获取Polling Time
export function getLang(): string | null {
    return localStorage.getItem(KNOW_NO_LANG);
}

// 存储 Polling Time
export function setLang(token: string) {
    localStorage.setItem(KNOW_NO_LANG, token);
}

// 删除Polling Time
export function removeLang(): void {
    return localStorage.removeItem(KNOW_NO_LANG);
}

// 定义Polling Time
const DEV_TOKEN = "dev_token";

// 获取Polling Time
export function getDevToken(): string | null {
    return localStorage.getItem(DEV_TOKEN);
}

// 存储 Polling Time
export function setDevToken(token: string) {
    localStorage.setItem(DEV_TOKEN, token);
}

// 删除Polling Time
export function removeDevToken(): void {
    return localStorage.removeItem(DEV_TOKEN);
}
