export const nameBmp = (name: { first: string; last: string; format: string }) => {
    switch (name.format) {
        case "{first} {last}":
            return `${name.first}${" "}${name.last}`;
        case "{first} {last}.":
            return `${name.first}${" "}${name.last}.`;
        case "{first}. {last}":
            return `${name.first}.${" "}${name.last}`;
        case "{first}{last}":
            return `${name.first}${name.last}`;
        case "{first}":
            return `${name.first}`;
        case "{last} {first}":
            return `${name.last}${" "}${name.first}`;
        case "{last}{first}":
            return `${name.last}${name.first}`;
        case "{last}. {first}":
            return `${name.last}.${" "}${name.first}`;
        case "{last}":
            return `${name.last}`;
        default:
            return `${name.first}${name.last}`;
    }
};
