export default {
    title: "应用搜索",
    no_data: "无数据",
    follow: "关注",
    reset: "重置",
    follow_success: "关注成功",
    follow_failed: "关注失败",
    share: "分享应用",
    share_address: "分享地址",
    copied: "已复制",
    copy_address: "复制地址",
    placeholder_msg: "请输入关键字",
    appInfo: "应用信息",
};
