import { Button, Modal, message } from "antd";
import React, { useEffect, useState } from "react";
import { appFollowApi, appShareInfoApi } from "../../services/api/application";
import { ResultType } from "../applicationSearch";
import NewAvatar from "../Avatar";
import style from "./index.module.less";
import { useTranslation } from "react-i18next";

interface PropType {
    closeModel: () => void;
    code: string;
}

const ApplicationInfo = (prop: PropType) => {
    const { closeModel, code } = prop;
    const { t } = useTranslation();
    const [appInfo, setAppInfo] = useState<ResultType>();

    // 获取分享应用的信息
    const getAppInfo = async () => {
        const info = await appShareInfoApi({ code });
        if (info.error.code === 0) {
            const { data } = info as { data: ResultType };
            setAppInfo(data);
        }
    };

    useEffect(() => {
        if (code) {
            getAppInfo();
        }
    }, []);

    // 关注应用
    const followApp = async () => {
        const info = await appFollowApi({ share_code: code, session: `${appInfo ? appInfo.session : ""}` });
        if (info.error.code === 0) {
            message.success(t("applicationSearch.follow_success"));
        } else {
            message.success(t("applicationSearch.follow_failed"));
        }
        const param = window.location.href.split("/?")[0];
        location.href = param;
        localStorage.setItem("code", "");
    };

    return (
        <Modal
            title={t("applicationSearch.appInfo")}
            centered
            open={true}
            onCancel={closeModel}
            width={500}
            footer={null}
            maskClosable={false}
        >
            {appInfo ? (
                <>
                    <div className={style.listBox}>
                        <div className={style.showSessionHead}>
                            <NewAvatar
                                info={{
                                    session: appInfo.session,
                                    item: appInfo.avatar,
                                    name: appInfo.name,
                                }}
                            />
                        </div>
                        <div className={style.showSessionDesc}>
                            <div>{appInfo.name}</div>
                            <div>{appInfo.desc}</div>
                        </div>
                    </div>
                    <div className={style.followBtn}>
                        <Button onClick={followApp}>{t("applicationSearch.follow")}</Button>
                    </div>
                </>
            ) : null}
        </Modal>
    );
};
export default ApplicationInfo;
