import React from "react";
import { CopyOutlined, LoadingOutlined, MenuFoldOutlined, RollbackOutlined } from "@ant-design/icons";
import style from "./ChatList.module.less";
import { Avatar, Input, Popover, message } from "antd";
import { getHistoryMessageListAPI, getMessageListAPI } from "../../services/api/message";
import MyEditor from "./BraftEditor";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setButtomVal, setHeartbeatNumber, setRobotSession, setRobotsTime } from "../../store/robots";
import { useRequest } from "ahooks";
import copy from "copy-to-clipboard";
import InfiniteScroll from "react-infinite-scroll-component";
import { dateDiff } from "../../utils/gitDateStr";
import Processing from "./processing";
import NewAvatar from "../Avatar";
import { nameBmp } from "../../utils/nameBmp";
import { RobotsInfo } from "../robotsList";
import { useTranslation } from "react-i18next";
import { appShareApi } from "../../services/api/application";
import share from "../../assets/share.png";
import SparkMD5 from "spark-md5";

export type MessageList = {
    content: {
        text: string;
        src?: string;
        image_uri?: string;
        file_uri?: string;
        file_name?: string;
    };
    type: string;
    from: string;
    create_at: number;
    id?: string;
};

const ChatList: React.FC = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    // 获取历史聊天列表（顺序为最新的为下标0，展示的时候需要反转数组）
    const [messageList, setMessageList] = React.useState<MessageList[]>([]);
    // 分享地址
    const [shareAddress, setShareAddress] = React.useState<string>("");
    // send发送的时间搓
    const robotsTime = useAppSelector((state) => state.robots.robotsTime);
    // 是否在最下面
    const isBottom = useAppSelector((state) => state.robots.isBottom);
    // 未加载数量
    const robotsNumber = useAppSelector((state) => state.robots.robotsNumber);
    // 用户信息
    const userinfo = useAppSelector((state) => state.UserManage.userinfo);
    // 机器人信息，包含session
    const selectAppInfo = useAppSelector((state) => state.robots.selectRobotsInfo);
    // 设置输入状态的返回数据
    const inputStateRes = useAppSelector((state) => state.robots.inputStateRes);

    // 机器人信息
    const [selectRobotsInfo, setSelectRobotsInfo] = React.useState<RobotsInfo | null>();
    // 加载的数量
    const [limit, setLimit] = React.useState<number>(1);
    // 是否在加载中
    const [inputType, setInputType] = React.useState<boolean>(false);
    // 聊天列表框的ref
    const chatListRef = React.useRef<any>(null);
    // inputStateRes监听是否在输入中
    React.useEffect(() => {
        if (selectAppInfo && Object.keys(inputStateRes).length > 0) {
            if (selectAppInfo.session === Object.keys(inputStateRes)[0]) {
                setInputType(inputStateRes[selectAppInfo.session]);
            } else {
                setInputType(false);
            }
        } else {
            setInputType(false);
        }
    }, [inputStateRes]);
    // 监听是否在最下面并且有新消息数量
    React.useEffect(() => {
        if (!selectRobotsInfo) return;
        if (isBottom && robotsNumber[selectRobotsInfo.session] > 0) {
            getMessageListBySession();
        }
    }, [robotsNumber]);
    // 点击切换的时候获取历史消息列表
    React.useEffect(() => {
        setMessageList([]);
        setSelectRobotsInfo(selectAppInfo);
    }, [selectAppInfo]);

    React.useEffect(() => {
        if (selectAppInfo) {
            setLimit(1);
            getHistoryMessageSession(1000);
        }
    }, [selectRobotsInfo]);

    // 拉取历史消息 直接拉满 每次加载10条  加满100条停止
    const getHistoryMessageSession = (limit = 1000) => {
        if (selectRobotsInfo) {
            const session = selectRobotsInfo.session;
            const obj = {
                session,
                timestamp: 0,
                limit,
            };
            getHistoryMessageListAPI(obj)
                .then((res) => {
                    if (res && res.data) {
                        const resdata = res.data as MessageList[];

                        const temp = resdata.map((item) => {
                            return {
                                id: SparkMD5.hash(JSON.stringify(item)),
                                ...item,
                            };
                        });
                        setMessageList(temp);
                    }
                })
                .catch(() => {
                    setLimit(1);
                    setMessageList([]);
                });
        }
    };
    // 拉取消息
    const getMessageListBySession = () => {
        if (!selectRobotsInfo || !robotsTime) return;
        getMessageListAPI({
            session: selectRobotsInfo.session,
            // 判断当前会话在初始化的时候有无获取到历史消息
            timestamp: robotsTime?.[selectRobotsInfo.session]?.flag
                ? robotsTime[selectRobotsInfo.session].timestamp
                : 0,
            limit: 100,
        }).then((res) => {
            if (res && res.data) {
                const temp = (res.data as MessageList[]).reverse();
                const tempList = [...temp, ...messageList];
                setMessageList(tempList);
                // 重置该条会话的心跳参数
                if (temp) {
                    dispatch(
                        setRobotsTime({
                            ...robotsTime,
                            [selectRobotsInfo.session]: { flag: true, timestamp: temp?.[0]?.create_at },
                        })
                    );
                }
                // 重置该条会话的新消息数
                dispatch(setHeartbeatNumber({ ...robotsNumber, [selectRobotsInfo.session]: 0 }));
                getHistoryMessageSession();
            }
        });
    };
    // 监听是否在底部
    const scrollChange = () => {
        if (!chatListRef.current) return;
        // scrollHeight像素高度
        dispatch(setButtomVal(chatListRef.current.scrollTop >= -10));
    };
    // 监听是否在底部的防抖函数
    const searchAntiShake = (): Promise<React.ChangeEvent<HTMLInputElement>> => {
        return new Promise(() => {
            setTimeout(() => {
                scrollChange();
            }, 1500);
        });
    };
    const { run } = useRequest(searchAntiShake, {
        debounceWait: 300,
        manual: true,
    });
    // 滚动监听
    React.useEffect(() => {
        // 滚动条滚动时触发
        window.addEventListener("scroll", run, true);
        return () => {
            window.removeEventListener("scroll", run, false);
        };
    }, []);
    // 监听聊天数据的变化，改变聊天容器元素的 scrollTop 值让页面滚到最底部
    const setRef = () => {
        if (!chatListRef.current) return;
        const current = chatListRef.current;
        current.scrollTop = 0;
    };
    // 上拉加载新数据
    const fetchMoreData = () => {
        setTimeout(() => {
            setLimit(limit + 1);
        }, 1000);
    };

    // 复制分享地址
    const copyAddress = () => {
        copy(shareAddress);
        message.success(t("applicationSearch.copied"));
    };

    // 分享地址
    const content = (
        <div className={style.chat_shareBox}>
            <span>{t("applicationSearch.share_address")}：</span>
            <Input readOnly value={shareAddress} />
            <CopyOutlined onClick={copyAddress} title={`${t("applicationSearch.copy_address")}`} />
        </div>
    );

    const getCode = async (session: string) => {
        const info = await appShareApi({ session: session });
        if (info.error.code === 0) {
            const { code } = info.data as { code: string };
            setShareAddress(`${location.href}/?code=${code}`);
        } else {
            message.error("分享码获取失败");
        }
    };

    return (
        <>
            {selectRobotsInfo && (
                <div className={style.chat_list}>
                    <div className={style.content}>
                        <div className={style.chat_header}>
                            {/* 返回 */}
                            <MenuFoldOutlined
                                onClick={() => dispatch(setRobotSession(null))}
                                className={style.backBox}
                            />
                            <nav className={style.chat_title}>
                                <NewAvatar
                                    info={{
                                        session: selectRobotsInfo.session,
                                        item: selectRobotsInfo.avatar,
                                        name: selectRobotsInfo.name,
                                    }}
                                ></NewAvatar>
                                <div className={style.nameAndDescBox}>
                                    <div className={style.chat_title_font}>{selectRobotsInfo.name}</div>
                                    {inputType ? (
                                        <div className={style.chat_desc_font}>正在输入中...</div>
                                    ) : (
                                        <div className={style.chat_desc_font}>{selectRobotsInfo.desc}</div>
                                    )}
                                </div>
                            </nav>
                            <div className={style.shareBox}>
                                {/* 分享 */}
                                {selectRobotsInfo.type === "app" ? (
                                    <Popover placement="bottomRight" title={null} content={content} trigger="click">
                                        <img
                                            src={share}
                                            title={`${t("applicationSearch.share")}`}
                                            onClick={() => getCode(selectRobotsInfo.session)}
                                            style={{ cursor: "pointer" }}
                                        />
                                    </Popover>
                                ) : null}
                            </div>
                        </div>
                        {!isBottom && robotsNumber[selectRobotsInfo.session] > 0 && (
                            <div
                                className={style.section_reminder}
                                onClick={() => {
                                    // 设置是否在最底的状态为真，表示已经到最下面了
                                    dispatch(setButtomVal(true));
                                    // 点击后到最低
                                    setRef();
                                    // 点完更新列表
                                    getMessageListBySession();
                                }}
                            >
                                查看新消息
                            </div>
                        )}
                        <div
                            id="scrollableDiv"
                            className={style.section}
                            ref={chatListRef}
                            style={{
                                overflow: "auto",
                                display: "flex",
                                // flexDirection: scrollBar ? "column-reverse" : "column",
                                flexDirection: "column-reverse",
                            }}
                        >
                            {/*Put the scroll bar always on the bottom*/}
                            <InfiniteScroll
                                dataLength={messageList.slice(0, limit * 20).length}
                                style={{
                                    display: "flex",
                                    flexDirection: "column-reverse",
                                }} //To put endMessage and loader to the top.
                                inverse={true}
                                hasMore={messageList.slice(0, limit * 20).length < messageList.length}
                                next={fetchMoreData}
                                loader={
                                    <div
                                        style={{
                                            textAlign: "center",
                                        }}
                                    >
                                        <LoadingOutlined /> Loading...
                                    </div>
                                }
                                scrollableTarget="scrollableDiv"
                            >
                                {messageList.length >= 0 ? (
                                    <>
                                        {selectRobotsInfo &&
                                            messageList.slice(0, limit * 20).map((item) => {
                                                return item.from !==
                                                    selectRobotsInfo.members.filter((value) => value.is_self)[0].id ? (
                                                    <div key={item.id}>
                                                        <div className={style.content_text_time_left}>
                                                            <div className={style.content_text_time_left_date}>
                                                                {
                                                                    selectRobotsInfo.members
                                                                        .filter((value) => !value.is_self)
                                                                        .find((val) => val.id === item.from)?.name
                                                                }
                                                                &nbsp;
                                                                {dateDiff(item.create_at)}
                                                            </div>
                                                        </div>
                                                        <div className={style.chat_receiver}>
                                                            {selectRobotsInfo.members
                                                                .filter((value) => !value.is_self)
                                                                .find((val) => val.id === item.from) &&
                                                            selectRobotsInfo.members
                                                                .filter((value) => !value.is_self)
                                                                .find((val) => val.id === item.from)?.avatar ? (
                                                                <Avatar
                                                                    size="large"
                                                                    src={
                                                                        selectRobotsInfo.members
                                                                            .filter((value) => !value.is_self)
                                                                            .find((val) => val.id === item.from)?.avatar
                                                                    }
                                                                    style={{
                                                                        backgroundColor: "#005EFF",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    size="large"
                                                                    style={{
                                                                        backgroundColor: "#005EFF",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    {
                                                                        selectRobotsInfo.members
                                                                            .filter((value) => !value.is_self)
                                                                            .find((val) => val.id === item.from)?.name
                                                                    }
                                                                </Avatar>
                                                            )}
                                                            <div className={style.content_text}>
                                                                <Processing item={item} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div key={item.id} className={style.right_content}>
                                                        <div className={style.content_text_time_right}>
                                                            <div className={style.content_text_time_right_date}>
                                                                {dateDiff(item.create_at)}
                                                                &nbsp;
                                                                {userinfo ? nameBmp(userinfo?.name) : null}
                                                            </div>
                                                        </div>
                                                        <div
                                                            className={style.chat_receiver}
                                                            style={{ justifyContent: "end" }}
                                                        >
                                                            <div className={style.content_text}>
                                                                <Processing item={item} />
                                                            </div>

                                                            {userinfo && userinfo.avatar ? (
                                                                <Avatar
                                                                    size="large"
                                                                    src={userinfo.avatar}
                                                                    style={{
                                                                        backgroundColor: "#fde3cf",
                                                                        color: "#f56a00",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <Avatar
                                                                    size="large"
                                                                    style={{
                                                                        backgroundColor: "#fde3cf",
                                                                        color: "#f56a00",
                                                                        verticalAlign: "middle",
                                                                    }}
                                                                >
                                                                    {userinfo?.name.first}
                                                                </Avatar>
                                                            )}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </InfiniteScroll>
                        </div>
                    </div>

                    <footer className={style.footer}>
                        <div>
                            <MyEditor
                                onSendOnChange={() => {
                                    // 移动到最下面
                                    setRef();
                                    getMessageListBySession();
                                }}
                            />
                        </div>
                    </footer>
                </div>
            )}
        </>
    );
};
export default ChatList;
