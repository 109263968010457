import React, { useEffect, useRef, useState } from "react";
import BraftEditor, { BuiltInControlType, DraftEditorProps, EditorState, HooksType } from "braft-editor";
import "braft-editor/dist/index.css";
import style from "./BraftEditor.module.less";
import useInputDebounce from "../../../hooks/useInputDebounceFn";
import { Button, Modal, Popover, Tooltip, Upload, UploadProps, message } from "antd";
import { FileUnknownOutlined, PlusCircleOutlined, SendOutlined } from "@ant-design/icons";
import useButtonDebounceFn from "../../../hooks/useButtonDebounceFn";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useSendMessage } from "./useSendMessage";

import Draft, { KeyBindingUtil } from "draft-js";
import { useTranslation } from "react-i18next";
import { RcFile } from "antd/lib/upload";
import { RobotsInfo } from "../../robotsList";
import { parseRichText } from "./parsingRichText";
type _Props = {
    onSendOnChange: () => void;
};
const MyEditor = React.memo((props: _Props) => {
    MyEditor.displayName = "MyEditor";

    const { uploadFile, sendMessage } = useSendMessage();

    const { t } = useTranslation();

    const { onSendOnChange } = props;

    const Ref = useRef<BraftEditor>(null);

    // 机器人信息，包含session
    const selectRobotsInfo = useAppSelector((state) => state.robots.selectRobotsInfo);

    // 聊天框值
    const [value, setValue] = React.useState<Draft.EditorState>(Draft.EditorState.createEmpty());

    const [lineFeed, setLineFeed] = useState(false);

    // 限制用户连续点击上传
    const [clickUpData, setClickUpData] = useState(true);

    useInputDebounce(value, 500);

    // 不显示加粗控件、斜体控件和下划线控件
    const excludeControls: BuiltInControlType[] = ["bold", "italic", "underline"];

    const hooks: HooksType = {
        "toggle-link": ({ href, target }: { href: string; target: string }) => {
            href = href.indexOf("http") === 0 ? href : `http://${href}`;
            return { href, target };
        },
    };

    // 发送按钮
    const onFinish = async () => {
        const data = Ref.current?.getValue().toHTML() && parseRichText(Ref.current?.getValue().toHTML());
        // 整合输入框数据
        const messageList: Array<{ type: "text" | "image"; message: string[] }> = [];
        // 当前字段类型
        let type = "";
        Array.isArray(data) &&
            data.forEach((it) => {
                if (it.type !== type) {
                    type = it.type;
                    messageList[messageList.length] = { type: it.type, message: [it.content] };
                } else {
                    messageList[messageList.length - 1].message.push(it.content);
                }
            });

        if (!selectRobotsInfo) return;
        try {
            for (const i of messageList) {
                if (i.type === "text" && i.message.join("\n").trim().length !== 0) {
                    if (i.message.some((e) => e.trim().length !== 0)) {
                        await sendMessage(selectRobotsInfo.session, i.message.join("\n"), "text");
                    }
                }
                if (i.type === "image") {
                    await uploadFile(i.message[0], selectRobotsInfo.session, "img");
                }
            }

            // 清空输入框
            Ref.current?.clearEditorContent();
            onSendOnChange();
        } catch (error) {
            console.log(error);
        }
    };

    // 自定义hooks
    const send = useButtonDebounceFn(onFinish, 500);

    const handelChange = (val: any) => {
        setValue(val);
    };

    // 动态按钮位置
    useEffect(() => {
        if ((Ref.current as unknown as { containerNode: { clientHeight: number } })?.containerNode.clientHeight > 54) {
            setLineFeed(true);
        } else {
            setLineFeed(false);
        }
    }, [Ref, value]);

    const draftProps: DraftEditorProps = {
        handleReturn(e) {
            e.preventDefault();
            if (KeyBindingUtil.hasCommandModifier(e) || KeyBindingUtil.isCtrlKeyCommand(e)) {
                send();
            } else {
                // 回车事件
                return "not-handled";
            }
            return "handled";
        },
        editorState: value,
        onChange: function (editorState: Draft.EditorState): void {
            Ref.current?.setValue(editorState as EditorState);
            setValue(editorState);
        },
        handleKeyCommand: () => "not-handled",
    };

    // 选择文件
    const [fileInfo, setFileInfo] = useState<{ file: File; base: string; type: "file" | "img" }>();
    // 打开文件展示框
    const [openModal, setOpenModal] = useState<boolean>(false);

    // 获取文件
    const beforeUpload = (file: RcFile, type: "img" | "file" | "document") => {
        const types = type === "document" ? "file" : type;
        const getFileData = (file: File) => {
            const reader = new FileReader();
            reader.addEventListener("load", () => {
                setFileInfo({ file, base: reader.result as string, type: types });
                setOpenModal(true);
            });
            reader.readAsDataURL(file);
        };

        if (type === "img") {
            if (file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg") {
                getFileData(file);
            } else {
                message.error(`${file.name} is not a png/jpg/jpeg file`);
            }
        } else if (type === "document") {
            if (file.type === "text/plain" || file.type === "application/pdf" || file.type === "application/epub+zip") {
                getFileData(file);
            } else {
                message.error(`${file.name} is not a epub/txt/pdf file`);
            }
        } else {
            getFileData(file);
        }
        return false;
    };

    // 选择more按钮
    const moreRender = () => {
        return (
            <div className={style.more}>
                <Upload
                    accept="image/*"
                    listType="picture"
                    showUploadList={false}
                    beforeUpload={(file) => beforeUpload(file, "img")}
                >
                    <div>{t("braftEditor.picture")}</div>
                </Upload>
                <Upload listType="picture" showUploadList={false} beforeUpload={(file) => beforeUpload(file, "file")}>
                    <div>{t("braftEditor.file")}</div>
                </Upload>
                <Upload
                    accept=".epub,.txt,.pdf"
                    listType="picture"
                    showUploadList={false}
                    beforeUpload={(file) => beforeUpload(file, "document")}
                >
                    <div>{t("braftEditor.document")}</div>
                </Upload>
            </div>
        );
    };

    // 展示选择文件框内容
    const modalFileRender = (
        robotsInfo: RobotsInfo,
        fileData: {
            file: File;
            base: string;
        }
    ) => {
        let node: JSX.Element = <></>;
        if (
            fileData.file.type === "image/png" ||
            fileData.file.type === "image/jpg" ||
            fileData.file.type === "image/jpeg"
        ) {
            node = <img className={style.moreSend} src={fileData.base} alt="" />;
        } else {
            node = (
                <div className={style.fileModal}>
                    <div className={style.fileModal_img}>
                        <FileUnknownOutlined />
                    </div>
                    <div className={style.fileModal_nav}>
                        <span>{fileData.file.name}</span>
                        <span>{(fileData.file.size / Math.pow(1024, 2)).toFixed(2) + "M"}</span>
                    </div>
                </div>
            );
        }
        return (
            <>
                <div>
                    {t("braftEditor.sendTo")}
                    {robotsInfo.name}
                </div>
                {node}
            </>
        );
    };

    const onOk = async () => {
        if (fileInfo && selectRobotsInfo) {
            setClickUpData(false);
            clickUpData &&
                (await uploadFile(fileInfo.base, selectRobotsInfo.session, fileInfo.type, fileInfo.file.name));
            setOpenModal(false);
            setClickUpData(true);
        }
    };
    const onCancel = () => {
        setOpenModal(false);
        setClickUpData(true);
    };

    return (
        <>
            <div className={style.braft}>
                {
                    <BraftEditor
                        ref={Ref}
                        hooks={hooks}
                        className={style.braft_editor}
                        draftProps={draftProps}
                        textBackgroundColor={false}
                        stripPastedStyles={true}
                        excludeControls={excludeControls}
                        onChange={handelChange}
                        placeholder={t("robotsList.send_to") + `${selectRobotsInfo?.name}`}
                        contentStyle={{ maxHeight: 100, fontSize: "14px" }}
                        style={lineFeed ? { width: "100%" } : {}}
                    />
                }
                <div className={style.buttonList}>
                    <Popover
                        placement="top"
                        title={""}
                        content={moreRender()}
                        overlayClassName={style.popoverMore}
                        trigger="click"
                    >
                        <Tooltip placement="top" title={t("braftEditor.evenMore")} trigger="hover">
                            <Button type="primary" onClick={() => ""} icon={<PlusCircleOutlined />}></Button>
                        </Tooltip>
                    </Popover>
                    <Tooltip
                        placement="topRight"
                        title={t(
                            /macintosh|mac os x/i.test(navigator.userAgent)
                                ? "braftEditor.send.mac"
                                : "braftEditor.send.win"
                        )}
                    >
                        <Button type="primary" onClick={send} icon={<SendOutlined />}></Button>
                    </Tooltip>
                </div>
            </div>
            {fileInfo && selectRobotsInfo && (
                <Modal
                    width={300}
                    closable={false}
                    open={openModal}
                    footer={
                        <div>
                            <Button onClick={onCancel}>{t("braftEditor.cancel")}</Button>
                            <Button disabled={!clickUpData} type="primary" onClick={onOk}>
                                {t("braftEditor.ok")}
                            </Button>
                        </div>
                    }
                >
                    {modalFileRender(selectRobotsInfo, fileInfo)}
                </Modal>
            )}
        </>
    );
});

export default MyEditor;
