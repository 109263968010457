import http from "../request";

// 上传-创建新上传会话
export const newuploadsessionApi = async <T>(data: IApi.Newuploadsession): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/res/newuploadsession",
        method: "post",
        data,
    });
};
// 上传-提交上传
export const commituploadsessionApi = async <T>(data: IApi.Commituploadsession): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/res/commituploadsession",
        method: "post",
        data,
    });
};
// 上传-创建群组头像上传会话
export const newGroupAvatarUploadSession = async <T>(
    data: IApi.NewGroupAvatarUploadSession
): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/res/newgroupavataruploadsession",
        method: "post",
        data,
    });
};
// 获取资源下载地址
export const getDownloadUrl = async <T>(data: IApi.ResGetDownloadUrl): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/res/getdownloadurl",
        method: "post",
        data,
    });
};
