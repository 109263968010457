/**
 * 处理消息列表中的文件，图片，文字分类
 * image-图片分类-image_uri
 * file-文件分类-file_uri
 * 文字用item.text即可
 * **/
import React from "react";
import { CloudDownloadOutlined, FileTextOutlined } from "@ant-design/icons";
import { MessageList } from ".";
import { getMessageListTypeAPI } from "../../services/api/message";
import style from "./ChatList.module.less";
import ReactMarkdown from "react-markdown";
// remark-gfm,添加了对删除线、表格、任务列表和 URL 的支持
import RemarkGfm from "remark-gfm";
import RemarkMath from "remark-math";
import RehypeKatex from "rehype-katex";
import RemarkBreaks from "remark-breaks";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vs } from "react-syntax-highlighter/dist/esm/styles/prism";
enum MessageType {
    TEXT = "text",
    IMAGE = "image",
    FILE = "file",
}

const Processing = (props: { item: MessageList }): JSX.Element => {
    const { item } = props;
    const [node, setNode] = React.useState<JSX.Element>(<></>);
    React.useEffect(() => {
        switch (item.type) {
            case MessageType.TEXT:
                setNode(
                    <ReactMarkdown
                        remarkPlugins={[RemarkMath, RemarkGfm, RemarkBreaks]}
                        rehypePlugins={[RehypeKatex]}
                        components={{
                            code({ children, ...props }) {
                                return (
                                    // @ts-ignore
                                    <SyntaxHighlighter style={vs} language={"javascript"} PreTag="div" {...props}>
                                        {String(children).replace(/\n$/, "")}
                                    </SyntaxHighlighter>
                                );
                            },
                        }}
                    >
                        {item.content.text}
                    </ReactMarkdown>
                );
                break;
            case MessageType.IMAGE:
                if (!item.content.image_uri) return;
                getMessageListTypeAPI({
                    res_uri: item.content.image_uri,
                }).then((res: any) => {
                    if (res.error.code !== 0 && !res.data.res_url) return;
                    setNode(<img src={res.data.res_url} alt="" />);
                });
                break;
            case MessageType.FILE:
                if (!item.content.file_uri) return;
                getMessageListTypeAPI({
                    res_uri: item.content.file_uri,
                }).then((res: any) => {
                    if (res.error.code !== 0 && !res.data.res_url) return;
                    setNode(() => {
                        return (
                            <div className={style.file_content}>
                                <div className={style.file}>
                                    <FileTextOutlined className={style.file_icon} />
                                </div>
                                <div className={style.file_text}>
                                    <div className={style.file_name}>
                                        {item.content.file_name
                                            ? item.content.file_name
                                            : decodeURI(res.data.res_url).split("?")[0].split("%2F").slice(-1)}
                                    </div>
                                    <div className={style.file_download}>
                                        <a href={res.data.res_url}>
                                            <CloudDownloadOutlined />
                                            下载
                                        </a>
                                        <div className={style.file_size}>
                                            {res.data.file_size > 0 ? `${res.data.file_size}kb` : "未知大小"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    });
                });
                break;
            default:
                setNode(<></>);
        }
    }, []);
    return <>{node}</>;
};
export default Processing;
