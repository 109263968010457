import { Button, Card, Modal, message } from "antd";
import Search from "antd/lib/input/Search";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import style from "./index.module.less";
import NewAvatar from "../Avatar";
import { appFollowApi, appSearchApi } from "../../services/api/application";

interface PropType {
    closeModel: () => void;
}

export interface ResultType {
    name: string;
    create_at: number;
    desc: string;
    avatar: string;
    type: string;
    session: string;
}

const ApplicationSearch = (prop: PropType) => {
    const { closeModel } = prop;
    const { t } = useTranslation();
    const [list, setList] = useState<ResultType[] | null>(null);
    const [valueText, setValueText] = useState<string>("");
    // 搜索
    const onSearch = async (value: string) => {
        const params: string[] = [];
        if (value.split(" ").length > 0) {
            value.split(" ").forEach((item) => {
                // 剔除空格
                if (item.trim()) {
                    params.push(item);
                }
            });
        }
        if (params.length > 0) {
            setValueText(value);
            const info = await appSearchApi({ keywords: params });
            if (info.error.code === 0) {
                const { result } = info.data as { result: ResultType[] };
                setList(result);
            }
        }
    };
    // 重置
    const onReset = () => {
        setList(null);
        setValueText("");
    };
    // 关注
    const followApp = async (session: string) => {
        if (session) {
            const info = await appFollowApi({ session: session });
            if (info.error.code === 0) {
                message.success(t("applicationSearch.follow_success"));
            } else {
                message.success(t("applicationSearch.follow_failed"));
            }
        }
    };

    return (
        <Modal
            title={t("applicationSearch.title")}
            centered
            open={true}
            onCancel={closeModel}
            width={500}
            footer={null}
        >
            <div className={style.controlBox}>
                <Search
                    placeholder={`${t("applicationSearch.placeholder_msg")}`}
                    value={valueText}
                    onSearch={onSearch}
                    enterButton
                    onChange={(e) => setValueText(e.target.value)}
                />
                <Button onClick={onReset}>{t("applicationSearch.reset")}</Button>
            </div>
            <div className={style.contentBox}>
                {list && list.length > 0 ? (
                    list.map((item) => {
                        return (
                            <Card title={null} key={item.session} style={{ marginBottom: "5px" }}>
                                <div className={style.listBox}>
                                    <div className={style.showSessionHead}>
                                        <NewAvatar
                                            info={{
                                                session: item.session,
                                                item: item.avatar,
                                                name: item.name,
                                            }}
                                        />
                                    </div>
                                    <div className={style.showSessionDesc}>
                                        <div>{item.name}</div>
                                        <div>{item.desc}</div>
                                    </div>
                                    <div className={style.follow} onClick={() => followApp(item.session)}>
                                        {t("applicationSearch.follow")}
                                    </div>
                                </div>
                            </Card>
                        );
                    })
                ) : (
                    <>{list ? `${t("applicationSearch.no_data")}` : null}</>
                )}
            </div>
        </Modal>
    );
};
export default ApplicationSearch;
