import React, { useEffect, useState } from "react";
import { Avatar, Input, Modal, Tooltip, Upload } from "antd";
import style from "./index.module.less";
import yesIcon from "../../assets/yes.png";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import uploadIcon from "../../assets/upload.png";
import { RcFile } from "antd/lib/upload";
import ReactCropper from "./CropperModel";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setFileData, setFileName, setFileSum, setFileUrl } from "../../store/file";
import SparkMD5 from "spark-md5";

interface PropsType {
    closeModel: () => void;
    onOK: (color: string, text: string) => void;
}

interface ColorList {
    color: string;
    flag: boolean;
}

const ChangeAvatar = (props: PropsType) => {
    const { closeModel, onOK } = props;
    const { t } = useTranslation();
    const [fileInfo, setFileInfo] = useState<string>("");
    const dispatch = useAppDispatch();
    const [open, setOpen] = useState(false);
    const [avatarText, setAvatarText] = useState<string>("");
    const [colorList, setColorList] = useState<ColorList[]>([
        { color: "#3370FF", flag: true },
        { color: "#7F3BF5", flag: false },
        { color: "#04B49C", flag: false },
        { color: "#2EA121", flag: false },
        { color: "#8FAC02", flag: false },
        { color: "#DC9B04", flag: false },
        { color: "#DE7802", flag: false },
        { color: "#F01D94", flag: false },
    ]);
    const [avatarColor, setAvatarColor] = useState<string>(colorList[0].color);
    // 文件url
    const fileUrl = useAppSelector((state) => state.file.file_url);

    useEffect(() => {
        if (fileUrl) {
            setAvatarColor("");
            const list: ColorList[] = [];
            colorList.forEach((item) => {
                if (item.color === "#3370FF") {
                    list.push({ ...item, flag: false });
                } else {
                    list.push(item);
                }
            });
            setColorList(list);
        }
    }, []);

    useEffect(() => {
        if (avatarText) {
            setAvatarColor("#3370FF");
            const list: ColorList[] = [];
            colorList.forEach((item) => {
                if (item.color === "#3370FF") {
                    list.push({ ...item, flag: true });
                } else {
                    list.push(item);
                }
            });
            setColorList(list);
        }
    }, [avatarText]);

    // 选择颜色
    const changeColor = (data: ColorList) => {
        const list: ColorList[] = [];
        colorList.forEach((item) => {
            if (item.color === data.color) {
                list.push({ ...item, flag: true });
            } else {
                list.push({ ...item, flag: false });
            }
        });
        setColorList(list);
        setAvatarColor(data.color);
    };

    // 选择文件
    const beforeUpload = (fileData: RcFile) => {
        if (
            fileData.type === "image/png" ||
            fileData.type === "image/jpg" ||
            fileData.type === "image/jpeg" ||
            fileData.type === "image/gif"
        ) {
            const getFileData = (file: Blob) => {
                const reader = new FileReader();
                reader.addEventListener("load", () => {
                    dispatch(setFileName(fileData.name));
                    setFileInfo(reader.result as string);
                    if (fileData.type === "image/gif") {
                        const url = URL.createObjectURL(fileData);
                        // 存地址
                        dispatch(setFileUrl(url));
                        const file_md5 = SparkMD5.ArrayBuffer.hash(reader.result as ArrayBuffer);
                        // 存文件md5值
                        dispatch(setFileSum(file_md5));
                        //
                        dispatch(setFileData(fileData));
                        closeModel();
                    } else {
                        setOpen(true);
                    }
                });
                if (fileData.type === "image/gif") {
                    reader.readAsArrayBuffer(fileData);
                } else {
                    reader.readAsDataURL(fileData);
                }
            };

            getFileData(fileData);
        } else {
            console.log(`${fileData.name} is not a png/jpg/jpeg file`);
        }
        return false;
    };

    // 保存
    const onSub = () => {
        if (avatarColor || avatarText) {
            // 重置数据
            dispatch(setFileName(""));
            dispatch(setFileUrl(""));
            dispatch(setFileSum(""));
            dispatch(setFileData(null));
            onOK(avatarColor, avatarText);
        }
        closeModel();
    };

    return (
        <Modal title={t("avatar.title")} centered open={true} onOk={onSub} onCancel={closeModel} width={600}>
            <div className={style.contentBox}>
                <div>
                    <Avatar
                        style={{ backgroundColor: avatarColor, verticalAlign: "middle" }}
                        src={fileUrl && !avatarText && !avatarColor ? fileUrl : null}
                        size={80}
                    >
                        {avatarText}
                    </Avatar>
                    <div>
                        <Upload beforeUpload={beforeUpload} listType="picture" showUploadList={false}>
                            <img src={uploadIcon} style={{ width: "20px" }} alt="" />
                        </Upload>
                    </div>
                </div>
                <div>
                    <div>{t("avatar.custom_font")}</div>
                    <Input
                        placeholder={`${t("avatar.input_placeholder")}`}
                        autoComplete="off"
                        maxLength={5}
                        onChange={(e) => setAvatarText(e.target.value)}
                    ></Input>
                    <div>
                        {t("avatar.change_color")}
                        <Tooltip title={t("avatar.tooltip_Info")}>
                            <QuestionCircleOutlined style={{ marginLeft: "10px", cursor: "pointer" }} />
                        </Tooltip>
                    </div>
                    <div>
                        {colorList.length > 0
                            ? colorList.map((item, index) => {
                                  return (
                                      <div
                                          key={index}
                                          style={{ backgroundColor: `${item.color}` }}
                                          onClick={() => changeColor(item)}
                                      >
                                          {item.flag ? <img src={yesIcon} alt="" style={{ width: "30px" }} /> : null}
                                      </div>
                                  );
                              })
                            : null}
                    </div>
                </div>
            </div>
            {open ? <ReactCropper imgURL={fileInfo} onCancel={() => setOpen(false)} closeModel={closeModel} /> : null}
        </Modal>
    );
};

export default ChangeAvatar;
