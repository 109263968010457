import { useDebounce } from "ahooks";
/**
@input输入防抖
@如果需要显示防抖的值，引用useInputDebounce处用const变量接收就行
@value-输入框的值
@wait-防抖毫秒
 **/
const useInputDebounce = (value: any, wait: number) => {
    const debouncedValue = useDebounce(value, { wait });
    return debouncedValue;
};
export default useInputDebounce;
