import React, { useEffect, useState } from "react";
import style from "./index.module.less";
import { useTranslation } from "react-i18next";
import { getToken, setToken, removeToken } from "../../services/auth";
import { startNewSessionApi, oauthAuth, getUserInfo, userLogout } from "../../services/api/login";
import { Button, Form, Input, Modal, message, Typography } from "antd";
import { nameBmp } from "../../utils/nameBmp";
import userHead from "../../assets/user.png";
import { useAppDispatch } from "../../store/hooks";
import { setUserInfo } from "../../store/userManage";
import { getQueryString } from "../../utils/getQueryString";
import { useNavigate } from "react-router-dom";

const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};

export interface UserInfo {
    avatar: string;
    link: { [K: string]: string };
    name: {
        first: string;
        last: string;
        format: string;
    };
}

const User = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const token = getToken();
    const navIgate = useNavigate();

    // http://127.0.0.1:5173/?code=ojNfe2Dy&state=d76022fb7f1feecb254e18b1ef654c57
    const codeValue: string | null = getQueryString("code");
    const stateValue: string | null = getQueryString("state");

    useEffect(() => {
        if (getToken() === undefined || getToken() === null) {
            if (codeValue && stateValue) {
            } else {
                // 必须是微信条件下才可以
                const isPc = navigator.userAgent;
                const result = isPc.toLowerCase().match(/MicroMessenger/i);
                if (/Mobi|Android|iPhone/i.test(isPc) && result !== null && result[0] === "micromessenger") {
                    goLogin();
                }
            }
        }
    }, []);

    useEffect(() => {
        if (getToken()) {
            return;
        }

        if (codeValue && stateValue) {
            oauthAuth({ code: codeValue, state: stateValue })
                .then((info) => {
                    const { code } = info.error;
                    const { session } = info.data as unknown as { session: string };
                    if (code === 0) {
                        setToken(session);
                        message.success(t("login.login_successful"));
                        navIgate("/app");
                    }
                })
                .catch((err) => {
                    message.error(t("login.login_fail"));
                    console.log(err);
                });
        }
    }, [codeValue]);

    // 登录
    const goLogin = () => {
        startNewSessionApi().then((info) => {
            const { code } = info.error;
            const { client_id, state } = info.data as unknown as { client_id: string; state: string };
            if (code === 0) {
                const redirect_uri = window.location.href.split("?")[0];
                window.location.href = `https://accounts.thetinyworld.cn/#oauthlogin/?client_id=${client_id}&state=${state}&redirect_uri=${redirect_uri}`;
            }
        });
    };

    return (
        <div className={style.loginBox}>
            <div className={style.title}>{t("login.welcome")}</div>
            <Button type="primary" shape="round" size="large" onClick={goLogin}>
                {t("login.login")}
            </Button>
        </div>
    );
};

export default User;
