import { configureStore } from "@reduxjs/toolkit";
import robots from "./robots";
import UserManage from "./userManage";
import file from "./file";

const store = configureStore({
    reducer: {
        robots,
        UserManage,
        file,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
