export default {
    home: "首页",
    user_center: "用户中心",
    app_list: "全部会话",
    version: "版本",
    settings: "设置",
    zhifou: "知否",
    ok: "确定",
    cancel: "取消",
    language: "语言",
    dev_token: "开发者令牌",
    yesterday: "昨天",
    headPortrait: "头像",
};
