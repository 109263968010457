import { DownOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Dropdown, Input, MenuProps, Modal, Popover, Space, message } from "antd";
import React, { useEffect, useState } from "react";
import { getDevToken, getToken, removeToken, setDevToken } from "../../services/auth";
import styles from "./index.module.less";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getSessionList, setRobotSession } from "../../store/robots";
import { GetSessionList } from "../robotsList";
import { useTranslation } from "react-i18next";
import zh from "../../assets/zh-CN.svg";
import en from "../../assets/en.svg";

import style from "./index.module.less";
import { userLogout } from "../../services/api/login";
import { nameBmp } from "../../utils/nameBmp";
import userHead from "../../assets/user.png";

interface SetUpProps {
    isShow: boolean;
    onCancel: (isShow: boolean) => void;

    openSettingsModel?: () => void;
}

enum Language {
    English = "English",
    Chinese = "简体中文",
}

const Settings: React.FC<SetUpProps> = (props: SetUpProps) => {
    const { isShow, openSettingsModel } = props;
    const dispatch = useAppDispatch();
    const [val, setVal] = useState<string>("");
    const { t, i18n } = useTranslation();
    const [open, setOpen] = useState(false);
    const [text, setText] = useState(localStorage.getItem("i18nextLng") === "zh" ? Language.Chinese : Language.English);
    const userinfo = useAppSelector((state) => state.UserManage.userinfo);

    // 选择语言下拉菜单
    const contentTow = (
        <div className={style.but}>
            <div
                className={style.userBut}
                onClick={() => {
                    void i18n.changeLanguage("zh");
                    setText(Language.Chinese);
                    setOpen(false);
                }}
            >
                <span>
                    <img src={zh} />
                </span>
                &nbsp;&nbsp;
                {Language.Chinese}
            </div>
            <hr />
            <div
                className={style.userBut}
                onClick={() => {
                    void i18n.changeLanguage("en");
                    setText(Language.English);
                    setOpen(false);
                }}
            >
                <span>
                    <img src={en} />
                </span>
                &nbsp;&nbsp;
                {Language.English}
            </div>
        </div>
    );
    // 退出登录
    const logout = () => {
        userLogout().then((info) => {
            const { code } = info.error;
            if (code === 0) {
                removeToken();
                message.success(t("login.logout"));
                // 刷新界面
                location.reload();
            }
        });
    };

    // 打开选择语言
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    // 下拉菜单
    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <a rel="noopener noreferrer" onClick={openSettingsModel}>
                    {t("home.settings")}
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a rel="noopener noreferrer" onClick={() => window.open("https://accounts.thetinyworld.cn")}>
                    {t("home.user_center")}
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a rel="noopener noreferrer" onClick={() => logout()}>
                    {t("login.logout")}
                </a>
            ),
        },
    ];

    useEffect(() => {
        const dev_token = getDevToken();
        dev_token && setVal(dev_token);
    }, []);

    const onOk = () => {
        const token = getToken();
        const dev_token = getDevToken();
        // 判断dev_token是否有改变
        if (token && dev_token !== val) {
            setDevToken(val);
            const param: GetSessionList = {
                token: token,
                dev_token: val,
            };
            // 获取会话列表
            dispatch(getSessionList(param));
            // 重置当前会话
            dispatch(setRobotSession(null));
        }

        props.onCancel(false);
    };
    const onCancel = () => {
        props.onCancel(false);
    };

    return (
        <Modal
            title={
                <Space size={12}>
                    <SettingOutlined style={{ fontSize: "20px" }} />
                    <div style={{ fontSize: "15px" }}>{t("home.settings")}</div>
                </Space>
            }
            centered
            open={isShow}
            onOk={onOk}
            okText={t("home.ok")}
            cancelText={t("home.cancel")}
            onCancel={onCancel}
        >
            <div className={styles.setUpLeft}>
                <div className={styles.title}>{t("home.headPortrait")}</div>
                <div className={styles.input}>
                    :{" "}
                    {userinfo ? (
                        <Dropdown menu={{ items }}>
                            <div>
                                <span>{userinfo ? nameBmp(userinfo?.name) : null}</span>
                                {"  "}
                                {userinfo?.avatar ? (
                                    <img className={style.avatar} src={userinfo ? userinfo.avatar : ""} alt="" />
                                ) : (
                                    <img src={userHead} alt="" />
                                )}
                            </div>
                        </Dropdown>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
            <div className={styles.setUpLeft}>
                <div className={styles.title}>{t("home.dev_token")}</div>
                <div className={styles.input}>
                    :{" "}
                    <Input
                        value={val}
                        onChange={(e) => {
                            setVal(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className={styles.setUpLeft}>
                <div className={styles.title}>{t("home.language")}</div>
                <div className={styles.input}>
                    :{" "}
                    <Popover
                        placement="bottomRight"
                        content={contentTow}
                        trigger="click"
                        open={open}
                        color="#f0f0f0"
                        onOpenChange={handleOpenChange}
                    >
                        <Button className={style.changeLanguage}>
                            <span>
                                {text === Language.Chinese && (
                                    <span>
                                        <img src={zh} />
                                    </span>
                                )}
                                {text === Language.English && (
                                    <span>
                                        <img src={en} />
                                    </span>
                                )}
                                &nbsp;&nbsp;
                                {text}
                                &ensp;
                                <DownOutlined />
                            </span>
                        </Button>
                    </Popover>
                </div>
            </div>
        </Modal>
    );
};
export default Settings;
