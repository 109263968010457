export default {
    "send.win": "发送 (Ctrl + Enter)",
    "send.mac": "发送 (Command + Enter)",
    evenMore: "更多",
    sendTo: "发送给",
    picture: "图片",
    file: "文件",
    document: "文档",
    ok: "确认",
    cancel: "取消",
};
