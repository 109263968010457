import http from "../request";

// 心跳
export const msgHeartbeat = async <T>(data: IApi.MsgHeartbeat): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/msg/heartbeat",
        method: "post",
        data,
    });
};

// 获取输入状态
export const msgInputState = async <T>(data: IApi.MsgCheckTyping): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/msg/checktyping",
        method: "post",
        data,
    });
};
