import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GetSessionList, RobotsInfo } from "../components/robotsList";
import { sessionList } from "../services/api/login";
import { getHistoryMessageListAPI } from "../services/api/message";
import { MessageList } from "../components/chatList";
import { HeartbeatResp, HeartbeatTimeReq } from "../hooks/useHeartbeat";
import { InputStateResp } from "../hooks/useInputState";

// 获取会话列表
export const getSessionList = createAsyncThunk("getSessionList", async (param: GetSessionList) => {
    // 会话列表
    let newList: RobotsInfo[] = [];
    // 心跳参数
    const newSession: HeartbeatTimeReq = {};

    if (param.token) {
        await sessionList({ dev_token: param.dev_token }).then(async (info) => {
            const { code } = info.error;
            if (code === 0) {
                const { list } = info.data as { list: RobotsInfo[] };
                if (list.length > 0) {
                    // 获取每条会话的id与最后一条消息的时间戳
                    for (const item of list) {
                        try {
                            const messageList = await getHistoryMessageListAPI({
                                session: item.session,
                                timestamp: 0,
                                limit: 10,
                            });

                            const { code } = messageList.error;
                            if (code !== 0) {
                                newSession[item.session] = { flag: false, timestamp: 0 };
                                continue;
                            }

                            const { data } = messageList as { data: MessageList[] };
                            if (data.length > 0) {
                                newSession[item.session] = { flag: true, timestamp: data[0].create_at };
                            } else {
                                newSession[item.session] = { flag: true, timestamp: 0 };
                            }
                        } catch (e) {
                            newSession[item.session] = { flag: false, timestamp: 0 };
                            continue;
                        }
                    }
                    newList = list.map((item) => item);
                }
            }
        });
    }

    return { sessionList: newList, list: newSession };
});

interface RobotType {
    // 当前选择的bot
    selectRobotsInfo: RobotsInfo | null;
    // 所有的bot
    sessionList: RobotsInfo[];
    // 消息列表以及个数
    robotsNumber: HeartbeatResp;
    // 消息列表以及时间
    robotsTime: HeartbeatTimeReq;
    // 是否在底部
    isBottom: boolean;
    // 输入状态轮循返回的数据
    // 注意：如果所有会话都没有输入状态则返回：{}
    inputStateRes: InputStateResp;
    mobile: boolean;
}

const initialState: RobotType = {
    selectRobotsInfo: null,
    sessionList: [],
    robotsNumber: {},
    robotsTime: {},
    isBottom: false,
    inputStateRes: {},
    mobile: false,
};

const slice = createSlice({
    name: "robotType",
    initialState,
    reducers: {
        // 设置当前会话
        setRobotSession: (state: RobotType, action: PayloadAction<RobotsInfo | null>) => {
            state.selectRobotsInfo = action.payload;
        },
        // 设置会话列表与消息数量
        setHeartbeatNumber: (state: RobotType, action: PayloadAction<{ [K: string]: number }>) => {
            state.robotsNumber = action.payload;
        },
        // 设置会话列表与时间戳
        setRobotsTime: (state: RobotType, action: PayloadAction<HeartbeatTimeReq>) => {
            state.robotsTime = action.payload;
        },
        // 设置是否在底部
        setButtomVal: (state: RobotType, action: PayloadAction<boolean>) => {
            state.isBottom = action.payload;
        },
        // 设置输入状态的返回数据
        setInputStateRes: (state: RobotType, action: PayloadAction<InputStateResp>) => {
            state.inputStateRes = action.payload;
        },
        // 设置是否是移动端
        setMobile: (state: RobotType, action: PayloadAction<boolean>) => {
            state.mobile = action.payload;
        },
    },
    // 处理异步函数
    extraReducers: (builder) => {
        builder.addCase(
            getSessionList.fulfilled,
            (
                state,
                action: PayloadAction<{
                    sessionList: RobotsInfo[];
                    list: HeartbeatTimeReq;
                }>
            ) => {
                // 改变会话列表
                state.sessionList = action.payload.sessionList;
                // 改变会话列表和时间戳
                state.robotsTime = action.payload.list;
            }
        );
    },
});

export const { setRobotSession, setHeartbeatNumber, setRobotsTime, setButtomVal, setInputStateRes, setMobile } =
    slice.actions;
export default slice.reducer;
