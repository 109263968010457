import React, { useState } from "react";
import style from "./index.module.less";
import { useAppSelector } from "../../store/hooks";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, MenuProps, Popover, message } from "antd";
import zh from "../../assets/zh-CN.svg";
import en from "../../assets/en.svg";
import userHead from "../../assets/user.png";
import { nameBmp } from "../../utils/nameBmp";
import { userLogout } from "../../services/api/login";
import { removeToken } from "../../services/auth";
import { DownOutlined } from "@ant-design/icons";

enum Language {
    English = "English",
    Chinese = "简体中文",
}

const Header = (props: { openSettingsModel: () => void }) => {
    const { openSettingsModel } = props;
    const { t, i18n } = useTranslation();
    const userinfo = useAppSelector((state) => state.UserManage.userinfo);
    const mobile = useAppSelector((state) => state.robots.mobile);

    const [text, setText] = useState(localStorage.getItem("i18nextLng") === "zh" ? Language.Chinese : Language.English);
    const [open, setOpen] = useState(false);

    // 退出登录
    const logout = () => {
        userLogout().then((info) => {
            const { code } = info.error;
            if (code === 0) {
                removeToken();
                message.success(t("login.logout"));
                // 刷新界面
                location.reload();
            }
        });
    };

    // 下拉菜单
    const items: MenuProps["items"] = [
        {
            key: "1",
            label: (
                <a rel="noopener noreferrer" onClick={openSettingsModel}>
                    {t("home.settings")}
                </a>
            ),
        },
        {
            key: "3",
            label: (
                <a rel="noopener noreferrer" onClick={() => window.open("https://accounts.thetinyworld.cn")}>
                    {t("home.user_center")}
                </a>
            ),
        },
        {
            key: "2",
            label: (
                <a rel="noopener noreferrer" onClick={() => logout()}>
                    {t("login.logout")}
                </a>
            ),
        },
    ];

    // 选择语言下拉菜单
    const contentTow = (
        <div className={style.but}>
            <div
                className={style.userBut}
                onClick={() => {
                    void i18n.changeLanguage("zh");
                    setText(Language.Chinese);
                    setOpen(false);
                }}
            >
                <span>
                    <img src={zh} />
                </span>
                &nbsp;&nbsp;
                {Language.Chinese}
            </div>
            <hr />
            <div
                className={style.userBut}
                onClick={() => {
                    void i18n.changeLanguage("en");
                    setText(Language.English);
                    setOpen(false);
                }}
            >
                <span>
                    <img src={en} />
                </span>
                &nbsp;&nbsp;
                {Language.English}
            </div>
        </div>
    );

    // 打开选择语言
    const handleOpenChange = (newOpen: boolean) => {
        setOpen(newOpen);
    };

    return (
        <div className={style.leftHeadBox}>
            {mobile ? (
                <></>
            ) : (
                <div className={style.title_user}>
                    <div className={mobile ? style.left_title_mobile : style.left_title}>{t("home.zhifou")}</div>
                    <>
                        {userinfo ? (
                            <Dropdown menu={{ items }}>
                                <div className={style.title_user_head}>
                                    <span style={{ width: "50px" }}>{userinfo ? nameBmp(userinfo?.name) : null}</span>
                                    {userinfo?.avatar ? (
                                        <img className={style.avatar} src={userinfo ? userinfo.avatar : ""} alt="" />
                                    ) : (
                                        <img src={userHead} alt="" />
                                    )}
                                </div>
                            </Dropdown>
                        ) : (
                            <></>
                        )}
                    </>
                    <Popover
                        placement="bottomRight"
                        content={contentTow}
                        trigger="click"
                        open={open}
                        color="#f0f0f0"
                        onOpenChange={handleOpenChange}
                    >
                        <Button className={style.changeLanguage}>
                            <span>
                                {text === Language.Chinese && (
                                    <span>
                                        <img src={zh} />
                                    </span>
                                )}
                                {text === Language.English && (
                                    <span>
                                        <img src={en} />
                                    </span>
                                )}
                                &nbsp;&nbsp;
                                {text}
                                &ensp;
                                <DownOutlined />
                            </span>
                        </Button>
                    </Popover>
                </div>
            )}
        </div>
    );
};

export default Header;
