import { Badge, Card, Spin } from "antd";
import React, { useEffect, useState } from "react";
import style from "./index.module.less";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setRobotSession } from "../../store/robots";
import NewAvatar from "../Avatar";

export interface GetSessionList {
    token: string;
    dev_token: string;
}

export interface RobotsInfo {
    avatar: string;
    session: string;
    name: string;
    create_at: number;
    desc: string;
    // 会话类型。"app":应用会话；"group":群组会话
    type: string;
    // 成员列表
    members: {
        // 成员(当前会话中的)id，和消息数据中的"from"字段对应
        id: string;
        // 是否是用户自己
        is_self: boolean;
        // 成员角色。2000-普通用户；2001-管理员；2002-群主
        role: number;
        // 成员类型。1000-应用；1001-用户
        type: number;
        avatar?: number;
        name?: number;
    }[];
}

export interface RobotsInfoExtra extends RobotsInfo {
    num?: number;
}

const RobotsList: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(true);
    // 当前会话信息
    const robot = useAppSelector((state) => state.robots.selectRobotsInfo);
    // 会话列表
    const sessionList = useAppSelector((state) => state.robots.sessionList);
    // 心跳的返回值
    const heartbeatNumber = useAppSelector((state) => state.robots.robotsNumber);
    // 会话列表（和sessionList不同的是有了心跳展示参数）
    const [currentList, setCurrentList] = useState<RobotsInfoExtra[]>([]);
    const [currentRobot, setCurrentRobot] = useState<RobotsInfoExtra>({
        avatar: "",
        session: "",
        desc: "",
        name: "",
        create_at: 0,
        num: 0,
        type: "",
        members: [],
    });

    useEffect(() => {
        if (sessionList.length > 0) {
            const newList = sessionList.map((item) => {
                return { ...item, num: 0 };
            });
            setLoading(false);
            setCurrentList(newList);
        } else {
            setCurrentList([]);
        }
    }, [sessionList]);

    useEffect(() => {
        if (heartbeatNumber && sessionList) {
            const list: RobotsInfoExtra[] = [];
            const keysArray = Object.keys(heartbeatNumber);
            sessionList.forEach((item) => {
                // 在设置dev_token后会话列表和robotTime的数据可能不匹配，所以需要做判断
                const index = keysArray.indexOf(item.session);
                if (index === -1) {
                    list.push({ ...item, num: 0 });
                } else {
                    for (let i = 0; i < keysArray.length; i++) {
                        if (item.session === keysArray[i]) {
                            // 扩展运算，修改对象属性的值
                            list.push({ ...item, num: heartbeatNumber[keysArray[i]] });
                        }
                    }
                }
            });
            setCurrentList(list);
        }
    }, [heartbeatNumber]);

    useEffect(() => {
        if (robot?.session) {
            // 当前会话
            setCurrentRobot(robot);
        }
    }, [robot]);

    // 选择会话
    const clickItem = (items: RobotsInfo) => {
        // 当前会话
        dispatch(setRobotSession(items));
    };

    return (
        <>
            <Spin
                spinning={sessionList.length === 0 && loading ? true : false}
                tip="loading..."
                style={{ height: "300px" }}
            >
                {sessionList.length > 0 ? (
                    currentList.map((item) => {
                        return (
                            <Card
                                onClick={() => clickItem(item)}
                                title={null}
                                style={currentRobot.session === item.session ? { border: "1px solid grey" } : {}}
                                className={style.robotsListBoxStyle}
                                key={item.session}
                            >
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <div className={style.showSessionHead}>
                                        <NewAvatar
                                            info={{
                                                session: item.session,
                                                item: item.avatar,
                                                name: item.name,
                                            }}
                                        />
                                    </div>
                                    <div className={style.showSessionDesc}>
                                        <div>
                                            <div>{item.name}</div>
                                            <Badge count={item.num} />
                                        </div>
                                        <div>{item.desc}</div>
                                    </div>
                                </div>
                            </Card>
                        );
                    })
                ) : (
                    <>{!loading && <div className={style.noSessionList}>{t("robotsList.no_session")}</div>}</>
                )}
            </Spin>
        </>
    );
};

export default RobotsList;
