import React, { useEffect } from "react";
import "./App.css";
import "./i18n";
import Home from "./Home";
import { message } from "antd";
import { getToken } from "./services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import { setMobile } from "./store/robots";
import { useAppDispatch } from "./store/hooks";

message.config({
    duration: 3,
    maxCount: 5,
});

function App() {
    const dispatch = useAppDispatch();
    const lolcat = useLocation();
    const navIgate = useNavigate();
    const whiteList = ["/login"];
    const isMobile = () => {
        const flag = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        return flag;
    };
    useEffect(() => {
        dispatch(setMobile(isMobile()));
    }, []);
    const hash: string = lolcat.pathname;
    useEffect(() => {
        if (hash.includes("/reset")) return;
        if (getToken() === undefined && !whiteList.includes(hash)) {
            navIgate("/login");
        } else if (getToken() !== undefined && whiteList.includes(hash)) {
            navIgate("/app");
        }
    }, [hash]);
    return <Home></Home>;
}

export default App;
