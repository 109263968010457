import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getToken } from "../../services/auth";
import User from "../user";

export default function Login() {
    const lolcat = useLocation();
    const navIgate = useNavigate();
    const whiteList = ["/login"];
    const hash: string = lolcat.pathname;
    const token = getToken();
    useEffect(() => {
        if (token === undefined && !whiteList.includes(hash)) {
            navIgate("/login");
        } else if (token !== undefined && whiteList.includes(hash)) {
            navIgate("/app");
        } else {
            navIgate("/login");
        }
    }, [hash, token]);
    return (
        <div style={{ width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <User />
        </div>
    );
}
