import { useDebounceFn } from "ahooks";
/**
@按钮防抖
@fn-防抖的函数
@wait-防抖毫秒
**/
const useButtonDebounceFn = (fn: () => void, wait: number) => {
    const { run } = useDebounceFn(fn, {
        wait,
    });
    return run;
};
export default useButtonDebounceFn;
