import http from "../request";

// 搜索应用
export const appSearchApi = async <T>(data: IApi.AppSearch): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/app/search",
        method: "post",
        data,
    });
};

// 关注应用
export const appFollowApi = async <T>(data: IApi.AppFollow): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/app/follow",
        method: "post",
        data,
    });
};

// 分享应用
export const appShareApi = async <T>(data: IApi.AppShare): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/app/share",
        method: "post",
        data,
    });
};
// 获取分享应用的信息
export const appShareInfoApi = async <T>(data: IApi.AppShareInfo): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/app/shareinfo",
        method: "post",
        data,
    });
};
