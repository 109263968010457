import { useRequest } from "ahooks";
import { msgInputState } from "../services/api/heartbeat";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setInputStateRes } from "../store/robots";

export type InputStateResp = {
    //key:会话id；value：是否为输入状态
    [K: string]: boolean;
};

const useInputState = () => {
    const dispatch = useAppDispatch();
    const [param, setParam] = useState<IApi.MsgCheckTyping>({ sessions: [] });
    // 会话列表
    const sessionList = useAppSelector((state) => state.robots.sessionList);
    useEffect(() => {
        if (sessionList.length > 0) {
            const sessionArray = sessionList.map((item) => item.session);
            setParam({ sessions: sessionArray });
        }
    }, [sessionList]);
    return useRequest(
        async () => {
            const info = await msgInputState({ ...param });
            const { code } = info.error;
            if (code === 0) {
                const { data } = info as unknown as { data: InputStateResp };
                // 输入状态的返回数据
                dispatch(setInputStateRes(data));
            }
        },
        {
            pollingInterval: 1500,
            // 是否手动开启轮循
            manual: true,
        }
    );
};
export default useInputState;
