import { useRequest } from "ahooks";
import { msgHeartbeat } from "../services/api/heartbeat";
import { useEffect, useState } from "react";

export type HeartbeatResp = {
    //bot 的 session，number为消息数量
    [K: string]: number;
};

export type HeartbeatTimeReq = {
    [K: string]: {
        flag: boolean;
        timestamp: number;
    };
};

const useHeartbeat = (heartbeatTimeReq: HeartbeatTimeReq) => {
    const [param, setParam] = useState<HeartbeatResp>({});

    useEffect(() => {
        if (heartbeatTimeReq) {
            const obj: HeartbeatResp = {};
            for (const key in heartbeatTimeReq) {
                // 提取心跳参数
                if (heartbeatTimeReq[key].flag) {
                    obj[key] = heartbeatTimeReq[key].timestamp;
                }
            }
            setParam(obj);
        }
    }, [heartbeatTimeReq]);

    return useRequest(
        async () => {
            const info = await msgHeartbeat({
                session: param,
            });
            const { code } = info.error;
            if (code === 0) {
                const { data } = info as { data: HeartbeatResp };
                return data;
            }
        },
        {
            pollingInterval: 3000,
            // 是否手动开启轮循
            manual: true,
        }
    );
};
export default useHeartbeat;
