import http from "../request";

// 拉取消息
export const getMessageListAPI = async <T>(data: IApi.MessageList) => {
    return await http<T>({
        url: "/msg/pull",
        method: "post",
        data,
    });
};
// 拉取历史消息
export const getHistoryMessageListAPI = async <T>(data: IApi.MsgHistory): Promise<IApi.IResponse<T>> => {
    return await http<T>({
        url: "/msg/history",
        method: "post",
        data,
    });
};
// 发送消息
export const sendMessageAPI = async <T>(data: IApi.SendMessage) => {
    return await http<T>({
        url: "/msg/send",
        method: "post",
        data,
    });
};
/**
 * 获取消息中的图片,文字和文件
 **/
export const getMessageListTypeAPI = async <T>(data: IApi.ResUri) => {
    return await http<T>({
        url: "/res/getdownloadurl",
        method: "post",
        data,
    });
};
// 创建群组
export const createChatGroup = async <T>(data: IApi.CreateChatGroup) => {
    return await http<T>({
        url: "/session/createchatgroup",
        method: "post",
        data,
    });
};
