import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MessageList } from "../components/chatList";
import { UserInfo } from "../components/user";

interface UserManage {
    userinfo?: UserInfo;
    chatList: MessageList[];
}

const initialState: UserManage = {
    chatList: [],
};

const slice = createSlice({
    name: "UserManage",
    initialState,
    reducers: {
        setUserInfo: (state: UserManage, action: PayloadAction<UserInfo>) => {
            state.userinfo = action.payload;
        },
        // chatList
        setChatList: (state: UserManage, action: PayloadAction<MessageList[]>) => {
            state.chatList = action.payload;
        },
    },
});

export const { setUserInfo, setChatList } = slice.actions;
export default slice.reducer;
