import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface FileInfoType {
    file_name: string;
    file_sum: string;
    file_url: string;
    fileData: File | null;
}

const initialState: FileInfoType = {
    file_name: "",
    file_sum: "",
    file_url: "",
    fileData: null,
};

const slice = createSlice({
    name: "robotType",
    initialState,
    reducers: {
        // 设置文件名
        setFileName: (state: FileInfoType, action: PayloadAction<string>) => {
            state.file_name = action.payload;
        },
        // 设置文件md5值
        setFileSum: (state: FileInfoType, action: PayloadAction<string>) => {
            state.file_sum = action.payload;
        },
        // 设置文件md5值
        setFileUrl: (state: FileInfoType, action: PayloadAction<string>) => {
            state.file_url = action.payload;
        },
        // 设置文件
        setFileData: (state: FileInfoType, action: PayloadAction<File | null>) => {
            state.fileData = action.payload;
        },
    },
});

export const { setFileName, setFileSum, setFileData, setFileUrl } = slice.actions;
export default slice.reducer;
