export default {
    "send.win": "Send (Ctrl + Enter)",
    "send.mac": "Send (Command + Enter)",
    evenMore: "Even More",
    sendTo: "Send To",
    picture: "Picture",
    file: "File",
    document: "Document",
    ok: "Ok",
    cancel: "Cancel",
};
