import React, { useEffect, useState } from "react";
import { Avatar } from "antd";
import { getDownloadUrl } from "../../services/api/uploading";

interface PropType {
    info: {
        item: string;
        name: string;
        session: string;
    };
}

const NewAvatar = (props: PropType) => {
    const { item, name, session } = props.info;

    const [avatarText, setAvatarText] = useState<string>("");
    const [avatarColor, setAvatarColor] = useState<string>("");
    const [avatarUrl, setAvatarUrl] = useState<string>("");

    // 获取头像地址
    const getAvatarUrl = async (avatar: string) => {
        try {
            const info = await getDownloadUrl({ res_uri: avatar });
            if (info.error.code === 0) {
                const { res_url } = info.data as { res_url: string; expires_in: number };
                setAvatarUrl(res_url);
            } else {
                setAvatarUrl("");
            }
        } catch (error) {
            console.log("====================================");
            console.log(error);
            console.log("====================================");
            setAvatarUrl("");
        }
    };

    useEffect(() => {
        setAvatarText("");
        setAvatarColor("");
        setAvatarUrl("");
        if (item) {
            if (new RegExp("^text").test(item) && item.includes("color")) {
                const text = item.slice(item.indexOf("=") + 1, item.indexOf("&"));
                const color = item.slice(item.lastIndexOf("=") + 1);
                setAvatarText(text);
                setAvatarColor(color);
            } else if (new RegExp("^text").test(item) && !item.includes("color")) {
                const text = item.slice(item.indexOf("=") + 1);
                setAvatarText(text);
                setAvatarColor("");
            } else if (new RegExp("^color").test(item) && !item.includes("text")) {
                const color = item.slice(item.lastIndexOf("/") + 1);
                setAvatarText("");
                setAvatarColor(color);
                setAvatarUrl("");
            } else if (new RegExp("^http").test(item)) {
                setAvatarUrl(item);
            } else if (new RegExp("^res").test(item) && !item.includes("<")) {
                // !item.includes("<") ：判断“<”只是因为测试的时候avatar参数设置错误导致的，不影响
                setAvatarText("");
                setAvatarColor("");
                getAvatarUrl(item);
            }
        }
    }, [session]);

    return (
        <Avatar
            style={{ backgroundColor: `${item && avatarColor ? avatarColor : "#3370FF"}`, verticalAlign: "middle" }}
            size="large"
            src={item && avatarUrl ? avatarUrl : null}
        >
            {item && avatarText ? avatarText : name.slice(0, 4)}
        </Avatar>
    );
};

export default NewAvatar;
