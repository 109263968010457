export function getQueryString(name: string) {
    const urlString = location.href;
    // 自验证参数
    // const urlString = "http://localhost:3000/login#/accounts?confirm_id=参数&code=参数";
    const params = urlString.split("?")[1];
    if (params !== undefined) {
        const queryString = params; // window.location.search
        if (queryString.length === 0) return null; // 如果无参，返回null
        const re = /[?&]?([^=]+)=([^&]*)/g;
        let tokens;
        while ((tokens = re.exec(queryString)) != null) {
            if (decodeURIComponent(tokens[1]) === name) {
                return decodeURIComponent(tokens[2]);
            }
        }
        return null;
    } else {
        return null;
    }
}
