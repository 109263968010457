export default {
    login: "Login",
    logout: "Log out",
    login_successful: "Login successful",

    // empower
    title: "Authorize based on the address bar information after successful login",
    code_checking: "Authorize code is empty",
    state_checking: "Authorize state is empty",
    submit: "Submit",
    cancel: "Cancel",

    welcome: "Welcome to Zhifou",
    login_fail: "Login failure",
};
