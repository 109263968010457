export default {
    title: "Application search",
    no_data: "No data",
    follow: "follow",
    reset: "Reset",
    follow_success: "Follow success",
    follow_failed: "Follow failed",
    share: "Share application",
    share_address: "Sharing address",
    copied: "copied",
    copy_address: "Copy address",
    placeholder_msg: "Please enter keywords",
    appInfo: "Application info",
};
